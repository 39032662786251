import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, ReplaySubject, take, takeUntil } from 'rxjs';

import { AuthService } from '../../auth/auth_service';
import { isErrorResponse } from '../../error_service/error_response';
import { FeatureFlagService } from '../../feature_flag/feature_flag_service';
import { ResourceTypes } from '../../landing/clip-bin-section/service/resource-types';
import { ResourceContent, ResourceService } from '../../landing/clip-bin-section/service/resource.service';
import { BinSectionContent } from '../../services/bin.service';
import { SnackBarService } from '../../services/snackbar_service';
import { StateService } from '../../services/state_service';
import { preventSpecialChars } from '../../utils/form.utils';

/**
 * Cloud ingest creation dialog
 */
@Component({
    selector: 'mam-clipbin-creation-folder-dialog',
    templateUrl: './clipbin_folder_creation_dialog.ng.html',
    styleUrls: ['./clipbin_folder_creation_dialog.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClipbinFolderCreationDialog implements OnInit, OnDestroy {
    @ViewChild('input') inputRef!: ElementRef;

    readonly form = new UntypedFormGroup({
        folderName: new UntypedFormControl('', Validators.required)
    });

    contextId = window.location.pathname.split('/').pop() || '';
    currentContext!: ResourceContent;
    keyEnabled = true;
    isAnotherOwnedFolder: boolean = false;
    blockControls$ = new BehaviorSubject<boolean>(false);
    hasClipBinInsideError$ = new BehaviorSubject<boolean>(false);

    hasNotAllowedCharacters$ = new BehaviorSubject<boolean>(false);
    wordNotAllowed$ = new BehaviorSubject<boolean>(false);

    constructor(
        private readonly snackBar: SnackBarService,
        readonly dialogRef: MatDialogRef<ClipbinFolderCreationDialog>,
        readonly resourceService: ResourceService,
        readonly cdr: ChangeDetectorRef,
        private readonly authService: AuthService,
        readonly featureService: FeatureFlagService,
        readonly stateService: StateService
    ) {}

    get maxLevelRestriction() {
        if (this.currentContext && this.currentContext.parent && this.currentContext.parent.level) {
            return this.currentContext
                ? this.currentContext.parent.level >= this.resourceService.MAX_FOLDER_DEPTH
                : false;
        } else {
            return false;
        }
    }

    ngOnInit() {
        this.subscribeAfterCloseAction(this.dialogRef);

        this.form.controls['folderName'].valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
          const { hasNotAllowedCharacters, wordNotAllowed } = preventSpecialChars(value);
          this.hasNotAllowedCharacters$.next(hasNotAllowedCharacters);
          this.wordNotAllowed$.next(wordNotAllowed);
        });
    }

    subscribeAfterCloseAction(dialogRef: MatDialogRef<ClipbinFolderCreationDialog>) {
      dialogRef.afterClosed().subscribe((creationFolderConfirmed) => {
        if (!creationFolderConfirmed || this.isDisabled()) {
          return;
        }

        this.keyEnabled = false;

        this.blockControls$.next(true);

        const formControls = this.form.controls;
        const folderName = formControls['folderName'].value;
        const parentId = window.location.pathname.includes(`folders`)
          ? window.location.pathname.split('/').pop() : undefined;
        this.resourceService
          .createResource(
            ResourceTypes.FOLDER,
            {
              name: folderName,
              owner: this.authService.getUserEmail(),
              ownerName: this.authService.getUserName(),
              parentId
            }
          )
          .pipe(take(1))
          .subscribe({
            next: (result) => {
              if (isErrorResponse(result)) {
                this.snackBar.error({
                  message: 'Create folder failed.',
                  details: result.message,
                  doNotLog: true
                });
                this.keyEnabled = true;
              } else {
                this.snackBar.message('Folder has been created');
                this.stateService.searchModeSelected$.next(BinSectionContent.FOLDER);
                this.stateService.returnToFirstPageOnGrid$.next(true);
              }
            },
            error: (error) => {
              console.error('Error creating Folder:', error);
              this.snackBar.error({
                message: 'Create folder failed.',
                details: error,
                doNotLog: true
              });
              this.keyEnabled = true;
            },
            complete: () => {
              this.blockControls$.next(false);
            }
          });
      });
    }

    isDisabled() {
        return (
            !this.form.valid ||
            this.blockControls$.value ||
            this.hasClipBinInsideError$.value ||
            this.hasNotAllowedCharacters$.value ||
            this.wordNotAllowed$.value ||
            this.isAnotherOwnedFolder
        );
    }

    ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
    }

    private readonly destroyed$ = new ReplaySubject<void>(1);
}
