import { Pipe, PipeTransform } from '@angular/core';

import { Bin, BinWithClips } from '../../services/bin.service';
import { Resource } from '../clip-bin-section/service/resource.service';

import { ResourceBin } from './clip-bin-preview';

@Pipe({name: 'getBinTitle' , pure: true})
export class BinTitlePipe implements PipeTransform {
  transform(binValue: BinWithClips | Bin | Resource | ResourceBin): string {
    if (this.isResource(binValue)) {
      return binValue?.name;
    } else if ('title' in binValue) {
      return binValue?.title;
    } else {
       throw new Error('Invalid binValue type');
    }
  }

  isResource(bin: BinWithClips | Bin | Resource | ResourceBin | undefined): bin is Resource {
    return bin != null && typeof bin === 'object' && 'iasData' in bin;
  }
}
