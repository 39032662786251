import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, ReplaySubject, takeUntil } from 'rxjs';

import { AuthService } from '../../auth/auth_service';
import { ResourceTypes } from '../../landing/clip-bin-section/service/resource-types';
import {
  Resource,
  ResourceResult,
  ResourceService,
} from '../../landing/clip-bin-section/service/resource.service';
import { SelectionService } from '../../landing/clip-bin-section/service/selection.service';
import { SnackBarService } from '../../services/snackbar_service';
import { StateService } from '../../services/state_service';

/** Clipbin rename dialog */
@Component({
  selector: 'mam-move-folder-dialog',
  templateUrl: './clipbin_folder_move_dialog.ng.html',
  styleUrl: './clipbin_folder_move_dialog.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClipBinFolderMoveDialog implements OnInit, OnDestroy {
  private readonly destroyed$ = new ReplaySubject<void>(1);
  resources$ = new BehaviorSubject<Resource[]>([]);
  selectedFolder$ = new BehaviorSubject<Resource | null>(null);
  selectedFolder: Resource | null = null;

  isNestedFolder: boolean = false;
  selectedNestedFolder: Resource | null = null;

  isLoading: boolean = false;

  keyEnabled = true;

  isMaximumLevelError$ = new BehaviorSubject<boolean>(false);
  hasClipBinInside$ = new BehaviorSubject<boolean>(false);

  static readonly dialogOptions = { hasBackdrop: true };

  constructor(
    readonly dialogRef: MatDialogRef<ClipBinFolderMoveDialog>,
    private readonly snackBar: SnackBarService,
    private readonly resourceService: ResourceService,
    private readonly selectionService: SelectionService,
    private readonly authService: AuthService,
    private readonly stateService: StateService,
    private readonly cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: Resource) {
  }

  ngOnInit(): void {
    this.subscribeAfterCloseAction(this.dialogRef);
    this.getResources();

    this.selectedFolder$.pipe(takeUntil(this.destroyed$)).subscribe(folder => {
      this.selectedFolder = folder;
      this.selectedNestedFolder = folder && folder.breadcrumb && folder.breadcrumb.length > 0 ? folder : null;
      this.cdr.markForCheck();
    });
  }

  subscribeAfterCloseAction(dialogRef: MatDialogRef<ClipBinFolderMoveDialog>) {
    dialogRef.afterClosed().subscribe((moveFolderConfirmed) => {
      if (!moveFolderConfirmed) return;
      this.moveFolderToSelected();
    });
  }

  getResources() {
    this.isLoading = true;
    this.isNestedFolder = false;

    this.isMaximumLevelError$.next(false);
    this.hasClipBinInside$.next(false);
    this.resourceService.getResource(ResourceTypes.FOLDER,
      { limit: 100, offset: 0 },//TODO check the limit
      { owner: this.authService.getUserEmail(), searchTerm: '', level: 0 },
      false,
      true
    )
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (resources) => {
          const result = resources as ResourceResult;
          this.resources$.next(result.folders.filter(folder => folder.level === 0));
        },
        complete: () => {
          this.isLoading = false;
          this.cdr.markForCheck();
        }
      });
  }

  getNestedResources(folder: Resource) {
    this.isLoading = true;
    this.isMaximumLevelError$.next(false);
    this.hasClipBinInside$.next(false);
    if (folder && folder.id) {
      this.resourceService.getResourceChildren(ResourceTypes.FOLDER,
        folder.id,
        { limit: 100, offset: 0 },
        false,
        true
      )
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: (result) => {
            this.isNestedFolder = true;
            this.selectedNestedFolder = result.parent as unknown as Resource;

            this.checkForMaximumDepth(this.data, folder);

            const hasClipBinInside = result.parent.children.findIndex((res) => res.type !== 'folder');
            if(hasClipBinInside > -1){
              this.hasClipBinInside$.next(true);
            }

            this.resources$.next(result.parent.children);
          },
          error: () => {
            this.isNestedFolder = false;
            this.selectedNestedFolder = null;
          },
          complete: () => {
            this.isLoading = false;
            this.cdr.markForCheck();
          }
        });
    }
  }

  /**
   * Checks if moving the provided source folder(s) into the target folder would violate the maximum allowed folder depth.
   *
   * @param sourceFolders The folder(s) intended to be moved. Accepts a single `Resource` or an array of `Resource` objects.
   * @param targetFolder The destination folder where the source folder(s) will be placed.
   * @param updateState If `true`, updates the `isMaximumLevelError$` observable with the result of the depth check.
   * @returns Returns `true` if any source folder, after being moved, exceeds the maximum depth; otherwise, returns `false`.
   *
   * @description
   * The function calculates the new depth as: `targetFolder.level + 1 + sourceFolder.subTreeDepth`.
   * With MAX_DEPTH = 3, the maximum allowed level is 2 (0, 1, 2), so newDepth must not exceed 3.
   */
  checkForMaximumDepth(sourceFolders: Resource | Resource[], targetFolder: Resource, updateState: boolean = true): boolean {
    const folders = Array.isArray(sourceFolders) ? sourceFolders : [sourceFolders];
    if (!folders || folders.length === 0) return false;

    let isAboveMaximum = false;
    const targetLevel = targetFolder.level ?? 0;
    const MAX_DEPTH = 2;

    for (const sourceFolder of folders) {
      if (!sourceFolder) continue;

      const allowedMaxDepth = MAX_DEPTH + (sourceFolder?.level !== undefined && sourceFolder?.subTreeDepth > 2 - sourceFolder.level && sourceFolder.level <= 2 ? 1 : 0);

      const newDepth = targetLevel + 1 + (sourceFolder.subTreeDepth ?? 0);

      if (newDepth > allowedMaxDepth) {
        isAboveMaximum = true;
        break;
      }
    }

    if (updateState) this.isMaximumLevelError$.next(isAboveMaximum);
    return isAboveMaximum;
  }

  selectFolder(folder: Resource) {
    this.isMaximumLevelError$.next(false);

    this.checkForMaximumDepth(this.data, folder);

    if (!this.isMaximumLevelError$.value && folder.directChildrenCount && folder.directChildrenCount > 0) {
      this.getNestedResources(folder);
    }

    this.selectedFolder$.next(folder);
  }

  selectHome() {
    this.isMaximumLevelError$.next(false);
    const homeFolder: Resource = {
      id: '0',
      name: 'Home',
      createdAt: '',
      displayName: 'Home',
      subTreeDepth: 0,
      level: 0
    };
    this.selectedFolder$.next(homeFolder);
  }

  checkDisabledFolder(folder: Resource): boolean {
    const isSameFolder = Array.isArray(this.data)
      ? this.data.some(item => item.id === folder.id)
      : this.data.id === folder.id;
    const wouldExceedMaxDepth = this.checkForMaximumDepth(this.data, folder, false);
    return isSameFolder || wouldExceedMaxDepth;
  }

  moveButtonDisableCondition() {
    return !this.selectedFolder$.value || !this.keyEnabled || this.isMaximumLevelError$.value || this.hasClipBinInside$.value;
  }

  moveFolderToSelected() {
    this.keyEnabled = false;

    const selectedFolder = this.selectedFolder$.value;

    if(selectedFolder && this.data && this.data.parent && selectedFolder.id === this.data.parent.id) {
      this.snackBar.error('You cannot move a folder to its own parent folder.');
      this.keyEnabled = true;
      return;
    }

    if (this.data.id && selectedFolder && selectedFolder.id) {
      this.resourceService.moveResource(ResourceTypes.FOLDER, this.data.id, selectedFolder.id)
      .subscribe({
          next: () => {
              this.snackBar.message('Folder has been moved successfully.');
              this.stateService.returnToFirstPageOnGrid$.next(true);
          },
          error: (error) => {
              this.snackBar.error(
                  'Folder could not be moved.', undefined, error);
              this.keyEnabled = true;
          },
      });
    } else if (Array.isArray(this.data) && selectedFolder && selectedFolder.id) {
      const foldersId: string[] = [];
      this.data.forEach(folder => foldersId.push(folder.id));
      this.selectionService.bulkMoveFolders(foldersId, selectedFolder.id).subscribe({
          next: () => {
              this.stateService.returnToFirstPageOnGrid$.next(true);
          },
          error: (error) => {
              this.snackBar.error(
                  'Folders could not be moved.', undefined, error);
              this.keyEnabled = true;
          },
      });
  }
  }

  backClicked() {
    const currentFolder = this.selectedFolder$.value;
    if (currentFolder && currentFolder.breadcrumb && currentFolder.breadcrumb.length > 0) {
      const lastVisitedFolder = currentFolder.breadcrumb[currentFolder.breadcrumb.length - 1];
      const updatedBreadcrumb = currentFolder.breadcrumb.slice(0, -1);
      lastVisitedFolder.breadcrumb = updatedBreadcrumb;
      this.selectedFolder$.next(lastVisitedFolder);
      this.selectedNestedFolder = lastVisitedFolder;
      this.getNestedResources(lastVisitedFolder);
    } else {
      this.selectedFolder$.next(null);
      this.selectedNestedFolder = null;
      this.getResources();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

}
