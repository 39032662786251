import { Pipe, PipeTransform } from '@angular/core';

import { BinSectionContent, BinSectionContentType } from '../../services/bin.service';

@Pipe({
    name: 'formatSearchMode',
    pure: true
})
export class FormatSearchMode implements PipeTransform {
    transform(value: BinSectionContentType | null | undefined): string {
        return this.formatBinSectionContent(value) as string;
    }
    
    formatBinSectionContent(mode: BinSectionContentType | null | undefined) {
      switch (mode) {
          case BinSectionContent.FOLDER:
              return 'Folders';
          case BinSectionContent.BIN:
              return 'Clip bins';
          case BinSectionContent.CLIP:
              return 'Clips';
          default:
              return '';
      }
    }
}

