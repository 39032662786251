import { BreakpointState } from '@angular/cdk/layout';

import { Asset } from '../../services/asset_service';
import { Bin, BinSectionContent, BinSectionContentType } from '../../services/bin.service';
import { DisplayMode } from '../../services/vod_search_service';

export const DEFAULT_PAGE_SIZE = 24;
export const SEARCH_DEBOUNCE = 500;
export const DEFAULT_DISPLAY_MODE = DisplayMode.GRID;
export const DEFAULT_SEARCH_MODE = BinSectionContent.BIN;

export type pageResult = {
    assets?: Asset[];
    bins?: Bin[];
    nextPageToken?: string;
};

// Define types for tagged emissions
interface Tagged<T> {
    source: string;
    value: T;
    timestamp: number;
}

// Define the exact tuple type for combineLatest output
export type UpdateResultsTuple = [
    Tagged<string | null>,
    Tagged<string>,
    Tagged<number>,
    Tagged<BinSectionContentType | null>,
    Tagged<DisplayMode>,
    Tagged<BreakpointState>
];
