import {ChangeDetectionStrategy, Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';

import { Resource, ResourceService } from '../../landing/clip-bin-section/service/resource.service';
import { SnackBarService } from '../../services/snackbar_service';
import { preventSpecialChars } from '../../utils/form.utils';

/** Clipbin rename dialog */
@Component({
  selector: 'mam-update-folder-dialog',
  templateUrl: './clipbin_folder_update_dialog.ng.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
    @import './shared/mixins';
    :host {
      max-width: 502px;
      width: 70vw;
      display: block;
      .error {
          color: red;
          font-size: 12px;
          margin-top: 4px;
      }

        @include breakpoint-md-max {
          max-width: 95vw;
          width: 65vw;
          }

        @include breakpoint-sm-max {
          width: initial;
          max-width: initial;
        }
      }`
  ]
})
export class ClipBinFolderUpdateDialog  {
  @ViewChild('input') input!: ElementRef;

  static readonly dialogOptions = {hasBackdrop: true};

  isBusy$ = new BehaviorSubject<boolean>(false);

  contextId = window.location.pathname.split('/').pop() || '';

  hasNotAllowedCharacters$ = new BehaviorSubject<boolean>(false);
  wordNotAllowed$ = new BehaviorSubject<boolean>(false);

  constructor(
    readonly dialogRef: MatDialogRef<ClipBinFolderUpdateDialog>,
    private readonly snackBar: SnackBarService,
    private readonly resourceService: ResourceService,
    @Inject(MAT_DIALOG_DATA) public data: Resource
  ) {
  }

  onSubmit(renamedTitle:string){
    const _renamedTitle = renamedTitle.trim();

    if (!_renamedTitle || _renamedTitle === this.data.displayName || this.isDisabledButton()) return;

    this.data.name = _renamedTitle;

    this.isBusy$.next(true);

    if(this.data.id) {
          this.dialogRef.close(_renamedTitle);
    }
  }

  onInput() {
    const inputValue = this.input.nativeElement.value;
    const { hasNotAllowedCharacters, wordNotAllowed } = preventSpecialChars(inputValue);
    this.hasNotAllowedCharacters$.next(hasNotAllowedCharacters);
    this.wordNotAllowed$.next(wordNotAllowed);
  }

  isDisabledButton(): boolean {
    if(!this.input || !this.input.nativeElement)
      return false;
    return this.input.nativeElement.value.trim().length === 0 || this.hasNotAllowedCharacters$.value || this.wordNotAllowed$.value;
  }

  isDisabledInput(): boolean {
    return this.isBusy$.value;
  }
}
