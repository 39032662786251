import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, ReplaySubject, takeUntil } from 'rxjs';

import { AuthService } from '../../auth/auth_service';
import { ResourceTypes } from '../../landing/clip-bin-section/service/resource-types';
import {
  Resource,
  ResourceContent,
  ResourceResult,
  ResourceService,
} from '../../landing/clip-bin-section/service/resource.service';
import { SelectionService } from '../../landing/clip-bin-section/service/selection.service';
import { Bin } from '../../services/bin.service';
import { SnackBarService } from '../../services/snackbar_service';
import { StateService } from '../../services/state_service';

@Component({
    selector: 'mam-move-bin-dialog',
    templateUrl: './clipbin_bin_move_dialog.ng.html',
    styleUrl: './clipbin_bin_move_dialog.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClipBinBinMoveDialog implements OnInit, OnDestroy {
    private readonly destroyed$ = new ReplaySubject<void>(1);
    resources$ = new BehaviorSubject<Resource[]>([]);
    selectedFolder$ = new BehaviorSubject<Resource | null>(null);
    selectedFolder: Resource | null = null;

    currentResources: Resource[] = [];

    isNestedFolder: boolean = false;
    selectedNestedFolder: Resource | null = null;

    resource?: Resource;

    isLoading: boolean = false;

    keyEnabled = true;

    hasFolderInside$ = new BehaviorSubject<boolean>(false);

    static readonly dialogOptions = { hasBackdrop: true };

    constructor(
        readonly dialogRef: MatDialogRef<ClipBinBinMoveDialog>,
        private readonly snackBar: SnackBarService,
        private readonly resourceService: ResourceService,
        private readonly router: Router,
        private readonly authService: AuthService,
        private readonly cdr: ChangeDetectorRef,
        private selectionService: SelectionService,
        private stateService: StateService,
        @Inject(MAT_DIALOG_DATA) public data: Resource
    ) {}

    ngOnInit(): void {
        this.subscribeAfterCloseAction(this.dialogRef);

        if (!Array.isArray(this.data)) {
            this.resource = this.convertToResource(this.data);
        }
        this.getResources();

        this.selectedFolder$.pipe(takeUntil(this.destroyed$)).subscribe((folder) => {
            this.selectedFolder = folder;
            this.selectedNestedFolder = folder && folder.breadcrumb && folder.breadcrumb.length > 0 ? folder : null;
            this.cdr.markForCheck();
        });
    }

    subscribeAfterCloseAction(dialogRef: MatDialogRef<ClipBinBinMoveDialog>) {
      dialogRef.afterClosed().subscribe((moveConfirmed) => {
        if (!moveConfirmed) return;
        this.moveFolderToSelected();
      });
    }

    getResources() {
        this.isLoading = true;
        this.isNestedFolder = false;
        this.hasFolderInside$.next(false);
        this.resourceService
            .getResource(
                ResourceTypes.FOLDER,
                { limit: this.resourceService.BASE_LIMIT, offset: 0 },
                { owner: this.authService.getUserEmail(), searchTerm: '', level: 0 },
                false,
                true
            )
            .pipe(takeUntil(this.destroyed$))
            .subscribe({
                next: (resources) => {
                    const result = resources as ResourceResult;
                    const folders = result.folders as Resource[];
                    if (this.resource && !Array.isArray(this.data)) {
                        const resourceId = this.resource.iasId ||
                            (this.resource.iasData?.label?.name) ||
                            this.resource.id;
                        const { foundResource, breadcrumb } = this.findResourceAndBuildBreadcrumb(folders, resourceId);
                        if (foundResource && resourceId) {
                            this.resource = {
                                ...this.resource,
                                level: breadcrumb.length,
                                breadcrumb: breadcrumb as Resource[],
                                parent: breadcrumb.length > 0 ? {
                                    id: breadcrumb[breadcrumb.length - 1].id,
                                    name: breadcrumb[breadcrumb.length - 1].name,
                                    displayName: breadcrumb[breadcrumb.length - 1].name,
                                    createdAt: '',
                                    subTreeDepth: 0
                                } : undefined
                            };
                            this.resources$.next(
                                folders.filter((folder) => folder.id !== resourceId && folder.level === 0)
                            );
                        } else {
                            this.resources$.next(folders.filter(folder => folder.level === 0));
                        }
                    } else {
                        this.resources$.next(folders.filter(folder => folder.level === 0));
                    }
                    this.cdr.markForCheck();
                },
                complete: () => {
                    this.isLoading = false;
                    this.cdr.markForCheck();
                }
            });
    }

    private findResourceAndBuildBreadcrumb(folders: Resource[], targetId: string | undefined): { foundResource: Resource | null, breadcrumb: { id: string, name: string, displayName: string, createdAt: string, subTreeDepth: number }[] } {
        const breadcrumb: { id: string, name: string, displayName: string, createdAt: string, subTreeDepth: number }[] = [];

        function searchFolders(nodes: Resource[]): Resource | null {
            for (const folder of nodes) {
                if (folder.id === targetId ||
                    folder.iasId === targetId ||
                    (folder.iasData?.label?.name === targetId)) {
                    return folder;
                }
                if (folder.children && folder.children.length > 0 && folder.id && folder.name) {
                    breadcrumb.push({
                        id: folder.id,
                        name: folder.name,
                        displayName: folder.displayName || folder.name,
                        createdAt: folder.createdAt || '',
                        subTreeDepth: folder.subTreeDepth || 0
                    });
                    const found = searchFolders(folder.children);
                    if (found) {
                        return found;
                    }
                    breadcrumb.pop();
                }
            }
            return null;
        }

        const foundResource = searchFolders(folders);
        return { foundResource, breadcrumb };
    }

    getNestedResources(folder: Resource) {
        this.isLoading = true;
        this.hasFolderInside$.next(false);
        if (folder && folder.id) {
            this.resourceService
                .getResourceChildren(ResourceTypes.FOLDER, folder.id, { limit: this.resourceService.BASE_LIMIT, offset: 0 }, false, true)
                .pipe(takeUntil(this.destroyed$))
                .subscribe({
                    next: (result) => {
                        this.isNestedFolder = true;
                        this.selectedNestedFolder = result.parent as unknown as Resource;
                        const folderIndex = result.parent.children.findIndex((val) => val.type === 'folder');
                        if (folderIndex >= 0) this.hasFolderInside$.next(true);

                        this.resources$.next(result.parent.children as Resource[]);
                    },
                    error: () => {
                        this.isNestedFolder = false;
                        this.selectedNestedFolder = null;
                    },
                    complete: () => {
                        this.isLoading = false;
                        this.cdr.markForCheck();
                    }
                });
        }
    }

    selectFolder(folder: Resource) {
        this.hasFolderInside$.next(false);

        if (!this.hasFolderInside$.value && folder.directChildrenCount && folder.directChildrenCount > 0) {
            this.getNestedResources(folder);
        }

        this.selectedFolder$.next(folder);
    }

    checkDisabledFolder(folder: Resource): boolean {
      return Array.isArray(this.data)
        ? this.data.some(item => item.id === folder.id)
        : this.data.id === folder.id;
    }

    selectHome() {
      const homeFolder: Resource = {
        id: '0',
        name: 'Home',
        createdAt: '',
        displayName: 'Home',
        subTreeDepth: 0,
        level: 0
      };
      this.selectedFolder$.next(homeFolder);
    }

    moveButtonDisableCondition() {
        return (
            !this.selectedFolder$.value ||
            !this.keyEnabled ||
            this.hasFolderInside$.value
        );
    }

    moveFolderToSelected() {
      this.keyEnabled = false;
      const selectedFolder = this.selectedFolder$.value;

      if (!selectedFolder) {
          this.snackBar.error('Please select a folder.');
          this.keyEnabled = true;
          return;
      }

      if (Array.isArray(this.data)) {
          const resourcesToMove: Resource[] = [];
          const invalidResources: Resource[] = [];

          for (const resource of this.data) {
              const convertedResource = this.convertToResource(resource);
              if (convertedResource && convertedResource.parent && selectedFolder.id === convertedResource.parent.id) {
                  invalidResources.push(convertedResource);
                  continue;
              }
              if (convertedResource && convertedResource.iasId) convertedResource.id = convertedResource.iasId;
              else if (convertedResource && convertedResource.iasData && convertedResource.iasData.label && convertedResource.iasData.label.name)
                  convertedResource.id = convertedResource.iasData.label.name;

              resourcesToMove.push(convertedResource);
          }

          if (invalidResources.length > 0) {
              this.snackBar.error(`Cannot move ${invalidResources.join(', ')} to its own parent folder.`);
              this.keyEnabled = true;
              return;
          }

          if (resourcesToMove.length > 0) {
            const binsIds:string[] = [];
            resourcesToMove.forEach(resource => {
              binsIds.push(resource.iasData.label.name);
            });

              this.selectionService.bulkMoveClipBins(binsIds, selectedFolder.id).subscribe({
                  next: () => {
                      this.snackBar.message('Clipbins have been moved successfully.');
                      this.stateService.returnToFirstPageOnGrid$.next(true);
                  },
                  error: (error) => {
                      this.snackBar.error('Clipbins could not be moved.', undefined, error);
                      this.keyEnabled = true;
                  },
              });
          } else {
              this.keyEnabled = true;
          }
      } else {
          // Handle single resource (your original logic)
          const resource = this.convertToResource(this.data);
          if (resource && resource.parent && selectedFolder.id === resource.parent.id) {
              this.snackBar.error('You cannot move a clip bin to its own parent folder.');
              this.keyEnabled = true;
              return;
          }
          if (resource && resource.iasId) resource.id = resource.iasId;
          else if (resource && resource.iasData && resource.iasData.label && resource.iasData.label.name)
              resource.id = resource.iasData.label.name;

          if (resource && resource.id && selectedFolder && selectedFolder.id) {
              this.resourceService.moveResource(ResourceTypes.CLIPBIN, resource.id, selectedFolder.id).subscribe({
                  next: () => {
                      this.snackBar.message('Clipbin has been moved successfully.');
                      this.stateService.returnToFirstPageOnGrid$.next(true);
                  },
                  error: (error) => {
                      this.snackBar.error('Folder could not be moved.', undefined, error);
                      this.keyEnabled = true;
                  },
              });
          }
      }
  }

  reloadCurrentRoute(selectedFolder: Resource, result?: Resource | ResourceContent) {
    const queryParams = { viewMode: this.resource?.displayMode };
    if (selectedFolder.id === '0') {
        this.router.navigate(['/'], {
            queryParams,
            queryParamsHandling: 'merge',
            onSameUrlNavigation: 'reload',
        });
    } else {
        const folderId = result?.parent?.id || selectedFolder.id;
        this.router.navigate(['/folders', folderId], {
            queryParams,
            queryParamsHandling: 'merge',
        });
      }
  }

    backClicked() {
      const currentFolder = this.selectedFolder$.value;
      if (currentFolder && currentFolder.breadcrumb && currentFolder.breadcrumb.length > 0) {
        const lastVisitedFolder = currentFolder.breadcrumb[currentFolder.breadcrumb.length - 1];
        const updatedBreadcrumb = currentFolder.breadcrumb.slice(0, -1);
        lastVisitedFolder.breadcrumb = updatedBreadcrumb;
        this.selectedFolder$.next(lastVisitedFolder);
        this.selectedNestedFolder = lastVisitedFolder;
        this.getNestedResources(lastVisitedFolder);
      } else {
        this.selectedFolder$.next(null);
        this.selectedNestedFolder = null;
        this.getResources();
      }
    }

    convertToResource(data: Resource | Bin): Resource {
        const bin = data as Bin;

        return bin && bin.title
            ? {
                  level: 0,
                  subTreeDepth: bin.assetCount != '' ? Number.parseInt(bin.assetCount) : 0,
                  createdAt: '',
                  displayName: bin.title,
                  id: bin.name,
                  name: bin.title
              }
            : (data as Resource);
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }
}
