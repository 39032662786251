<!-- Clip Bins Section -->
<section #clipbinsSection class="clipbins" [class.loading]="resultsLoading">
    <header [class.mam-border--bottom]="displayMode === 'list'" class="clipbins_header">
        <div class="clipbins_header_wrapper">
            <div class="clipbins_header_owner">
                <!-- Show mode dropdown -->
                <ng-container matSuffix>
                    <button
                        #menuShowAllTrigger="matMenuTrigger"
                        (click)="$event.stopPropagation()"
                        [class.active]="menuShowAllTrigger.menuOpen"
                        [matMenuTriggerFor]="menuShowMode"
                        class="show-mode_dropdown"
                        mam-firebase-ga="Open search mode selector via dropdown"
                        mat-button
                    >
                        <div class="show-mode_dropdown_inner">
                            <span>{{ username && showAllAssets ? 'All items' : username + "'s Page" }}</span>
                            <mat-icon class="arrow">arrow_drop_down</mat-icon>
                        </div>
                    </button>

                    <!-- Show mode menu -->
                    <mat-menu
                        #menuShowMode="matMenu"
                        class="show-mode_search__dropdown_options search_dropdown_options"
                    >
                        <div class="mat-menu-title">Choose a mode</div>
                        <button
                            (click)="showAllAssets ? setShowAll(false) : null"
                            class="search_dropdown_options__item"
                            mam-firebase-ga="Select search mode"
                            mat-menu-item
                        >
                            <mat-icon [style.opacity]="!showAllAssets ? 1 : 0">done</mat-icon>
                            <span>{{ username + "'s Page" }}</span>
                        </button>
                        <button
                            (click)="!showAllAssets ? setShowAll(true) : null"
                            [disabled]="searchModeDisabled"
                            class="search_dropdown_options__item"
                            mam-firebase-ga="Select search mode"
                            mat-menu-item
                        >
                            <mat-icon [style.opacity]="showAllAssets ? 1 : 0">done</mat-icon>
                            <span>All items</span>
                        </button>
                    </mat-menu>
                </ng-container>

                <div class="clipbins_header_owner__actions">
                    <!-- Display mode toggle -->
                    <button
                        (click)="this.toggleViewMode()"
                        [mam-firebase-ga]="'Toggle ' + (isGrid(displayMode) ? 'list view' : 'grid view')"
                        [matTooltip]="'Switch to ' + (isGrid(displayMode) ? 'list view' : 'grid view')"
                        class="clipbins_actions__display-mode bins_display-mode"
                        color="primary"
                        mat-mini-fab
                    >
                        <mat-icon class="material-icons-outlined">
                            {{ isGrid(displayMode) ? 'list' : 'view_module' }}
                        </mat-icon>
                    </button>

                    <!-- New action button -->
                    <div class="clipbins_create_job mobile-upper-toolbar">
                        <mam-new-action-button
                            (clickAction)="executeCreateAction($event)"
                            [actions]="createActions"
                            [optionSpacing]="false"
                            [small]="true"
                            [squared]="false"
                            origin="clip bin section"
                        ></mam-new-action-button>
                    </div>
                </div>
            </div>

            <div class="clipbins_header_search">
                <!-- Search box -->
                <mat-form-field
                    #searchField
                    [class.input-on-focus]="searchInputFocus"
                    [class.input-without-focus]="!searchInputFocus"
                    [class.is-focused]="searchInput?.focus"
                    appearance="outline"
                    class="clipbins_search-form"
                    subscriptSizing="dynamic"
                >
                    <mat-icon class="clipbins_search-form__icon" matPrefix>search </mat-icon>


                    <!-- Chip grid for search terms -->
                    <mat-chip-grid
                    (dblclick)="searchInputFocus=true"
                    (touchstart)="searchInputFocus=true"
                    #chipList aria-label="Search terms">
                        @for (term of searchTerms; track term; let i = $index) {
                        <mat-chip-row (removed)="removeTerm(i)" class="text-ellipsis" [removable]="true">
                            {{ term }}
                            <button aria-label="Remove term" matChipRemove>
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </mat-chip-row>
                        }


                    <input
                        #searchInput
                        (focus)="searchInputFocus = true;"
                        (keydown)="searchInputFocus = true"
                        (touchstart)="searchInputFocus=true"
                        (keydown.enter)="performSearch()"
                        (blur)="searchInputFocus = false"
                        (matChipInputTokenEnd)="addTerm($event)"
                        [formControl]="searchControl"
                        [matChipInputFor]="chipList"
                        [placeholder]="getPlaceholderText()"
                        aria-label="Search inside clip bins section"
                        class="clipbins_search-form__input"
                        matInput
                        type="text"
                    />

                  </mat-chip-grid>
                    @if(searchTerms.length && !searchInputFocus) {
                      <mat-chip-row
                      matSuffix
                      (touchstart)="searchInputFocus=true"
                      (dblclick)="searchInputFocus=true" class="chip--counter">
                          {{ searchTerms.length }}+
                      </mat-chip-row>
                      }

                    <!-- Clear icon: shown if there are chips or input value -->
                    @if (searchTerms.length > 0 || searchControl.value) {
                    <button
                        mat-icon-button
                        matSuffix
                        aria-label="Clear"
                        matTooltip="Clear"
                        mam-firebase-ga="Clear search input"
                        (click)="onSearchClear(); $event.stopPropagation()"
                        class="clipbins_search-form__clear clear-button"
                    >
                        <mat-icon>close</mat-icon>
                    </button>
                    }

                    <!-- Search mode dropdown -->
                    <ng-container matSuffix>
                        @if (searchTerms.length > 0 || searchControl.value) {
                        <mat-divider [vertical]="true"></mat-divider>
                        }
                        <button
                            #menuTrigger="matMenuTrigger"
                            (click)="$event.stopPropagation()"
                            [class.active]="menuTrigger.menuOpen"
                            [matMenuTriggerFor]="menu"
                            class="clipbins_search__dropdown search_dropdown"
                            mam-firebase-ga="Open search mode selector via dropdown"
                            mat-button
                        >
                            <div class="search_dropdown_inner">
                                <span class="hidden-on-mobile">{{ resourceService.selectedSearchMode$  | async | formatSearchMode }}</span>
                                <mat-icon class="arrow hidden-on-mobile">arrow_drop_down</mat-icon>
                                <mat-icon class="show-on-mobile">filter_list</mat-icon>
                            </div>
                        </button>

                        <!-- Search mode menu -->
                        <mat-menu #menu="matMenu" class="clipbins_search__dropdown_options search_dropdown_options">
                            <div class="mat-menu-title">RESULT TYPE</div>
                            @for (mode of searchModeOptions; track mode) {
                            <button
                                mat-menu-item
                                class="search_dropdown_options__item"
                                (click)="handleSearchModeChange(mode)"
                                mam-firebase-ga="Select search mode"
                                [mam-firebase-resource]="mode"
                                [disabled]="searchModeDisabled"
                            >
                                <mat-icon [style.opacity]="mode === searchModeSelected ? 1 : 0">done</mat-icon>
                                <span>{{ formatBinSectionContent(mode) }}</span>
                            </button>
                            }
                        </mat-menu>
                    </ng-container>
                </mat-form-field>
                <div class="clipbins_header_search--container"></div>
            </div>
        </div>
        <mat-progress-bar [class.invisible]="!resultsLoading" mode="indeterminate">
        </mat-progress-bar>

        <div class="clipbins_header_actions">
            <!-- Grid actions -->
            @if (displayMode === 'grid') {
            <div class="clipbins_actions">
                <div class="clipbins_actions_bulk">
                    <!-- Select/De-select all. -->
                    <mat-checkbox
                        class="bulk-actions__select-all"
                        color="primary"
                        matTooltipPosition="above"
                        mam-firebase-ga="Toggle selection for all segments in search results"
                        (change)="$event ? toggleAllSelect($event.checked) : null"
                        [checked]="selectionService.selectAll$()"
                        [indeterminate]="selectionService.currentSelection.size > 0 && !selectionService.selectAll$()"
                    >
                    </mat-checkbox>
                    @if (selectionService.currentSelection.size > 0) {
                    <button
                        mat-icon-button
                        (click)="handleBulkDelete(this.selectionService.currentSelection)"
                        class="bulk-actions__button main__icon--size"
                        aria-label="Delete selected assets"
                        matTooltip="Delete selected assets"
                        matTooltipPosition="above"
                        mam-firebase-ga="Delete selected assets"
                    >
                        <mat-icon class="material-icons-outlined">delete_outline</mat-icon>
                    </button>
                    @if (!showAllAssets) {
                    <button
                        mat-icon-button
                        (click)="handleBulkMove(this.selectionService.currentSelection)"
                        class="bulk-actions__button main__icon--size"
                        aria-label="Move selected assets"
                        matTooltip="Move selected assets"
                        matTooltipPosition="above"
                        mam-firebase-ga="Move selected assets"
                    >
                        <mat-icon class="material-icons-outlined">drive_file_move_outline</mat-icon>
                    </button>
                    } } @if (this.itemMultiSelection.size !== 0) {
                    <div class="bulk-actions">
                        <button mat-icon-button (click)="deleteSelection()">
                            <mat-icon class="material-icons-outlined">delete</mat-icon>
                        </button>
                    </div>
                    }
                </div>
                <div class="clipbins_actions_pagination">
                    @switch (searchModeSelected) { @case (SEARCH_MODE.BIN) {
                    <mat-paginator
                        hidePageSize
                        class="main__mat-paginator--border"
                        [length]="binsPagination.totalCount"
                        [pageSize]="binsPagination.pageSize"
                        [pageIndex]="binsPagination.pageIndex"
                        [disabled]="resultsLoading || binsPagination.totalCount === binsPagination.pageSize"
                        (page)="onPageChange($event)"
                        aria-label="Select page"
                    >
                    </mat-paginator>
                    } @case (SEARCH_MODE.CLIP) {
                    <mat-paginator
                        hidePageSize
                        class="main__mat-paginator--border"
                        [length]="clipsPagination.totalCount"
                        [pageSize]="clipsPagination.pageSize"
                        [pageIndex]="clipsPagination.pageIndex"
                        [disabled]="resultsLoading"
                        (page)="onPageChange($event)"
                        aria-label="Select page"
                    >
                    </mat-paginator>
                    } @case (SEARCH_MODE.FOLDER) {
                    <mat-paginator
                        hidePageSize
                        class="main__mat-paginator--border"
                        [length]="foldersPagination.totalCount"
                        [pageSize]="foldersPagination.pageLimit"
                        [pageIndex]="foldersPagination.pageIndex"
                        (page)="onPageChange($event)"
                        [disabled]="resultsLoading || folderResults.length === 0"
                        aria-label="Select page"
                    >
                    </mat-paginator>
                    } }
                </div>
            </div>
            }
        </div>
    </header>

    <!-- List and Grid -->
    @if (!folderUrlId || (searchText != null && searchText !== '')) { @if (resources$ | async; as results) { @if
    (resultsLoading && !results.length) {
    <!-- Loading -->
    <mam-empty-assets-card [loading]="true"></mam-empty-assets-card>
    } @else { @if (!results.length && !resultsLoading) { @if (hasClipBinsLoaded$ | async) {
    <!-- Empty bins -->
    <mam-empty-assets-card
        [assetsType]="formatBinSectionContent(searchModeSelected)"
        [hasSearchText]="!!searchText"
        [helperText]="true"
    >
        <p>
            Use the <span><mat-icon>add</mat-icon> New</span> button in the top left to create one and start collecting
            {{ formatBinSectionContent(searchModeSelected) }}.
        </p>
    </mam-empty-assets-card>
    } } @else { @if (displayMode === 'list') {
    <!-- List view -->
    <div class="list-view-wrapper">
        <mam-cbo-list-display
            [isAdmin]="isAdmin"
            [isShowAll]="showAllAssets"
            [startAfter]="listViewStartAfter || ''"
            [owner]="showAllAssets ? '' : userEmail"
            [result]="results"
            [selection]="itemMultiSelection"
        ></mam-cbo-list-display>
    </div>
    } @else {
    <!-- Display results -->
    <div class="clipbins_grid grid--{{searchModeSelected}}">
        @switch (searchModeSelected) { @case (SEARCH_MODE.CLIP) { @for (clip of clipResults; track clip) {
        <div class="clipbins_grid_card_container">
            <mam-clip-preview class="clip-preview" [clip]="clip" [viewMode]="displayMode"></mam-clip-preview>
        </div>
        } } @case (SEARCH_MODE.FOLDER) { @for (folder of folderResults; track folder) {
        <div class="clipbins_grid_card_container folder_grid_card_container">
            <div class="clipbins_checkbox" *featureFlagOn="'use-clips-selection'">
                <mat-checkbox
                    color="primary"
                    (change)="$event ? toggleSelect(folder) : null"
                    [checked]="selectionService.isSelected(folder) || selectionService.selectAll$()"
                >
                </mat-checkbox>
            </div>
            <mam-clip-bin-folder-preview
                [isAdmin]="isAdmin"
                [userEmail]="userEmail"
                [folder]="folder"
                [viewMode]="displayMode"
                [showAllFolders]="showAllAssets"
                class="clipbins_grid__card folder_grid__card"
            ></mam-clip-bin-folder-preview>
        </div>
        } } @case (SEARCH_MODE.BIN) { @for (bin of binResults; track bin) {
        @if (bin.iasData) {
        <div class="clipbins_grid_card_container">
            <div class="clipbins_checkbox" *featureFlagOn="'use-clips-selection'">
                <mat-checkbox
                    color="primary"
                    (change)="$event ? toggleSelect(bin) : null"
                    [checked]="selectionService.isSelected(bin) || selectionService.selectAll$()"
                >
                </mat-checkbox>
            </div>
              <mam-clip-bin-preview
                  [bin]="bin"
                  [checkboxPresent]="true"
                  [isAdmin]="isAdmin"
                  [binsViewMode]="displayMode"
                  [active]="itemMultiSelection.has(bin.name)"
                  class="clipbins_grid__card"
              ></mam-clip-bin-preview>
            </div>
          } } } }
    </div>
    } } } } } @else {
    <mam-folder-content
        #folderContent
        [isAdmin]="isAdmin"
        [userEmail]="userEmail"
        [folderId]="folderUrlId"
        [displayMode]="displayMode"
        [searchTerm]="searchText || ''"
        [pageIndex$]="pageIndex$"
        (folderContentLoading)="onFolderContentLoading($event)"
    ></mam-folder-content>
    }

    <!-- Pagination -->
    @if (displayMode === 'grid') {
    <div class="pagination_bottom">
        <div class="clipbins_actions_pagination">
            @switch (searchModeSelected) {
              @case (SEARCH_MODE.BIN) {
            <mat-paginator
                hidePageSize
                class="main__mat-paginator--border"
                [length]="binsPagination.totalCount"
                [pageSize]="binsPagination.pageSize"
                [pageIndex]="binsPagination.pageIndex"
                [disabled]="resultsLoading || binsPagination.totalCount === binsPagination.pageSize"
                (page)="onPageChange($event)"
                aria-label="Select page"
            >
            </mat-paginator>
            } @case (SEARCH_MODE.CLIP) {
            <mat-paginator
                hidePageSize
                class="main__mat-paginator--border"
                [length]="clipsPagination.totalCount"
                [pageSize]="clipsPagination.pageSize"
                [pageIndex]="clipsPagination.pageIndex"
                [disabled]="resultsLoading"
                (page)="onPageChange($event)"
                aria-label="Select page"
            >
            </mat-paginator>
            } @case (SEARCH_MODE.FOLDER) {
            <mat-paginator
                hidePageSize
                class="main__mat-paginator--border"
                [length]="foldersPagination.totalCount"
                [pageSize]="foldersPagination.pageLimit"
                [pageIndex]="foldersPagination.pageIndex"
                (page)="onPageChange($event)"
                [disabled]="resultsLoading || folderResults.length === 0"
                aria-label="Select page"
            >
            </mat-paginator>
            } }
        </div>
    </div>
    }
</section>
