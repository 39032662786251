<mat-card
  appearance="outlined"
  class="clipbin_card"
  [class.mam-elevation-1]="binsViewMode === 'grid'"
  [class.clipbin_card--list]="binsViewMode === 'list'"
  [routerLink]="['/clipbin', getClipBinId(binValue), 'clip', 0]"
  [queryParams]="{'type': 'bin'}"
  [class.active]="active"
  queryParamsHandling="merge"
  mam-firebase-ga="Open clip bin from its preview"
  [mam-firebase-resource]="binValue.name"
>
  <div class="card-header-image hidden-on-mobile">
    @if (binWithClips.clips?.length) {
      <mam-asset-thumbnail *ngFor="let asset of binWithClips?.clips | slice:0:3; trackBy: trackClip"
                           [asset]="asset">
      </mam-asset-thumbnail>
    }
  </div>
  <div class="card-content">
    <div class="card-content-width">
      <div class="card-content-title" [attr.title]="binValue | getBinTitle">
        <div class="name-side-icon">
          <mat-icon role="img" aria-hidden="true"> subscriptions</mat-icon>
        </div>
        <p>{{ (binValue | getBinTitle) || binValue.name}}</p>
      </div>
      <div class="card-content-subtitle location-wrapper" [ngPlural]="(binValue | assetCount: isLocationExpanded)">
        @if (getBinAsResourceBin(); as resourceBin) {
          @if (resourceBin.id) {
          <mam-breadcrumb
            class="location-wrapper__breadcrumb breadcrumb--min-space"
            (isExpandedChange)="onIsExpandedChange($event)"
            [resource]="resourceBin"
          ></mam-breadcrumb>
          }
        }
        <div class="plural-wrapper">
          <ng-template ngPluralCase="=0">No clips</ng-template>
          <ng-template ngPluralCase="=1">1 clip</ng-template>
          <ng-template ngPluralCase="other">{{ (binValue | assetCount: isLocationExpanded) }} clips</ng-template>
          <ng-template ngPluralCase="=-1"></ng-template>
        </div>
      </div>
    </div>
    @if (shouldShow3DotMenu()) {
      <button
        mat-icon-button
        class="bin-button main__icon--size"
        [matMenuTriggerFor]="menu"
        aria-label="Open Clip Bin Menu"
        (click)="$event.stopPropagation()"
        mam-firebase-ga="Open clip bin menu"
        [mam-firebase-resource]="binValue.name"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu" class="clip-bin-options-menu">
        @if (isClipbin(binValue)) {
            <button
              matTooltipPosition="left"
              [matTooltip]="(binValue | assetCount: isLocationExpanded) === 0 ? 'No clips to share' : ''"
              mat-menu-item
              [disabled]="(binValue | assetCount: isLocationExpanded) === 0"
              (click)="openShareBin(binValue)"
              mam-firebase-ga="Open clip bin share dialog"
              [mam-firebase-resource]="binValue.name"
            >
              Share clip bin
            </button>
        }

        @if (shouldShowOtherButtons()) {
          <button
            mat-menu-item
            (click)="openRenameBin(getTitle(binValue), binValue.name)"
            mam-firebase-ga="Open clip bin rename dialog"
            [mam-firebase-resource]="binValue.name"
          >
            Rename
          </button>
          <button
            *ngIf="!showAllBins"
            mat-menu-item
            (click)="openMoveFolder()"
            mam-firebase-ga="Open clip bin move dialog"
            [mam-firebase-resource]="binValue.name"
          >
            Move
          </button>
          <button
            mat-menu-item
            (click)="openDeleteBin(binValue)"
            class="delete-button"
            mam-firebase-ga="Select clip bin deletion"
            [mam-firebase-resource]="binValue.name"
          >
            Delete clip bin
          </button>
        }
      </mat-menu>
    } @else {
      <button
        mat-icon-button
        class="bin-button main__icon--size"
        [matMenuTriggerFor]="menu"
        aria-label="Open Clip Bin Menu"
        (click)="$event.stopPropagation()"
        mam-firebase-ga="Open clip bin menu"
        [mam-firebase-resource]="binValue.name"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
        <mat-menu #menu="matMenu" class="clip-bin-options-menu">
          <button
            matTooltipPosition="left"
            [matTooltip]="(binValue | assetCount: isLocationExpanded) === 0 ? 'No clips to share' : ''"
            mat-menu-item
            [disabled]="(binValue | assetCount: isLocationExpanded) === 0"
            (click)="openShareBin(binValue)"
            mam-firebase-ga="Open clip bin share dialog"
            [mam-firebase-resource]="binValue.name"
          >
            Share clip bin
          </button>
        </mat-menu>
    }


  </div>
</mat-card>
