import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  ViewChild
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';

import { preventSpecialChars } from '../utils/form.utils';

/** Clipbin rename dialog */
@Component({
    selector: 'mam-rename-bin-dialog',
    templateUrl: './rename_bin_dialog.ng.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [
        `
            @import './shared/mixins';

            :host {
                max-width: 502px;
                width: 70vw;
                display: block;
                .error {
                    color: red;
                    font-size: 12px;
                    margin-top: 4px;
                }

                @include breakpoint-md-max {
                    max-width: 95vw;
                    width: 65vw;
                }

                @include breakpoint-sm-max {
                    width: initial;
                    max-width: initial;
                }
            }
        `
    ]
})
export class RenameBinDialog {
    static readonly dialogOptions = { hasBackdrop: true };
    @ViewChild('input') input!: ElementRef;
    isBusy$ = new BehaviorSubject<boolean>(false);
    hasNotAllowedCharacters$ = new BehaviorSubject<boolean>(false);
    wordNotAllowed$ = new BehaviorSubject<boolean>(false);

    constructor(
        readonly dialogRef: MatDialogRef<RenameBinDialog>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            title: string;
            name: string;
        }
    ) {}

    onInput() {
      const inputValue = this.input.nativeElement.value;
      const { hasNotAllowedCharacters, wordNotAllowed } = preventSpecialChars(inputValue);
      this.hasNotAllowedCharacters$.next(hasNotAllowedCharacters);
      this.wordNotAllowed$.next(wordNotAllowed);
    }

    saveNewTitle(newTitle: string) {
      this.dialogRef.close({newTitle, name: this.data.name});
    }

    isDisabledInput() {
    return this.isBusy$.value;
    }

    isDisabledButton(): boolean {
        if (!this.input || !this.input.nativeElement) return false;
        return this.input.nativeElement.value.trim().length === 0 || this.isBusy$.value || this.hasNotAllowedCharacters$.value || this.wordNotAllowed$.value;
    }


}
