import { BreakpointObserver } from '@angular/cdk/layout';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatChipGrid, MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatFormField } from '@angular/material/form-field';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  EMPTY,
  map,
  merge,
  Observable,
  of,
  ReplaySubject,
  startWith,
  Subject,
  take,
  takeUntil,
} from 'rxjs';

import { AuthService } from '../../auth/auth_service';
import { FeatureFlagService } from '../../feature_flag/feature_flag_service';
import { Clip } from '../../services/asset_service';
import {
  Bin,
  BinSectionContent,
  BinSectionContentType,
  BinService,
} from '../../services/bin.service';
import { ClipbinsOwner } from '../../services/bin_api.service';
import { ClipApiService } from '../../services/clip_api_service';
import { MediaCacheService } from '../../services/media_cache_service';
import { Pagination, PaginationService } from '../../services/pagination_service';
import { PaginatorIntl, UNKNOWN_LENGTH } from '../../services/paginator-intl';
import { SearchType } from '../../services/search_api.service';
import { SearchInputService } from '../../services/search_input_service';
import { StateService } from '../../services/state_service';
import { DisplayMode } from '../../services/vod_search_service';
import { ClipBinBinMoveDialog } from '../../shared/clipbin_bin_move_dialog/clipbin_bin_move_dialog';
import {
  ClipbinFolderCreationDialog,
} from '../../shared/clipbin_folder_creation_dialog/clipbin_folder_creation_dialog';
import {
  ClipBinFolderDeleteDialog,
} from '../../shared/clipbin_folder_delete_dialog/clipbin_folder_delete_dialog';
import {
  ClipBinFolderMoveDialog,
} from '../../shared/clipbin_folder_move_dialog/clipbin_folder_move_dialog';
import { CreateBinDialog } from '../../shared/create_bin_dialog';
import { DeleteBinDialog, DeleteBinDialogData } from '../../shared/delete_bin_dialog';
import {
  DeleteMultipleBinDialog,
} from '../../shared/delete_multiple_bin_dialog/delete_multiple_bin_dialog';
import { ScrubbingService } from '../../shared/scrubbing_service';
import { actionOptionErrorMessage } from '../../utils/cbo-actions.utils';
import { FolderContentComponent } from '../folder-content/folder-content.component';
import {
  BINS_PAGINATION_BREAKPOINTS,
  Breakpoint,
  DisplayModePagination,
} from '../landing-helpers.utils';

import {
  DEFAULT_DISPLAY_MODE,
  DEFAULT_PAGE_SIZE,
  DEFAULT_SEARCH_MODE,
  pageResult,
  SEARCH_DEBOUNCE,
  UpdateResultsTuple,
} from './clip-bin-section.constants';
import { mapSearchEventStream } from './rxjs-event-helpers';
import {
  OrganizerEnum,
  OrganizersActionOptions,
  OrganizerTypes,
  ResourceType,
  ResourceTypes,
} from './service/resource-types';
import {
  IResourceService,
  PaginationInfo,
  Resource,
  ResourceResult,
  ResourceService,
  SearchOptions,
} from './service/resource.service';
import { RouterExtensionService } from './service/router-extension.service';
import { SelectionService } from './service/selection.service';
import { extractUrlDetails } from './url-helpers';


type ResultItem = Resource | Bin | Clip;

/**
 * Component for displaying and managing clip bins and folders.
 * Provides search, filtering, pagination, and display mode options.
 */
@Component({
    selector: 'mam-clip-bin-section',
    templateUrl: './clip-bin-section.component.html',
    styleUrls: ['./clip-bin-section.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: MatPaginatorIntl, useClass: PaginatorIntl }]
})
export class ClipBinSection implements AfterViewInit, OnDestroy, OnInit {
    @ViewChild('searchInput') searchInput?: ElementRef<HTMLInputElement>;
    @ViewChild('searchField') searchField?: MatFormField;
    @ViewChild('scrollableView') scrollableView!: ElementRef<HTMLElement>;
    @ViewChildren(MatPaginator) paginators!: QueryList<MatPaginator>;
    @ViewChild('folderContent', { static: false }) folderContentComponent?: FolderContentComponent;
    @ViewChild('clipbinsSection', { static: false }) clipbinsSection?: ElementRef<HTMLElement>;
    @ViewChild('chipList') chipList!: MatChipGrid;

    /* Pagination */
    /** Pagination object for bins. */
    binsPagination: Pagination<pageResult>;
    /** Pagination object for clips. */
    clipsPagination: Pagination<pageResult>;
    /** Pagination object for folders. */
    foldersPagination = {
        pageLimit: DEFAULT_PAGE_SIZE,
        nextCursor: null as Resource | null,
        lastCursor: null as Resource | null,
        pageIndex: 0,
        totalCount: 0
    };

    pageIndex$ = new BehaviorSubject(0);

    /* Loading */
    /** Indicates if results are loading. */
    resultsLoading = true;
    /** Observable for loading state */
    resultsLoading$: Observable<number[]> = of(0).pipe(
        take(1),
        map(() => Array.from({ length: DEFAULT_PAGE_SIZE }))
    );

    /* Controls lock */
    controlsLocked$ = new BehaviorSubject<boolean>(false);
    searchControl = new FormControl<string | null>('');
    searchTerms: string[] = []; // Array to store committed search terms as chips
    resources$ = new BehaviorSubject<Bin[] | Clip[] | Resource[]>([]);
    /** searchMode array of available choices. */
    searchModeOptions = this.featureService.featureOn('enable-clip-search')
        ? [BinSectionContent.FOLDER, BinSectionContent.BIN, BinSectionContent.CLIP]
        : [BinSectionContent.FOLDER, BinSectionContent.BIN];
    searchModeSelected: BinSectionContentType = DEFAULT_SEARCH_MODE;
    searchText: string | null = null;
    displayMode = DEFAULT_DISPLAY_MODE;
    SEARCH_MODE = BinSectionContent;
    searchModeDisabled = false;
    searchInputFocus = false;
    // Display booleans
    /** Show all assets (including those from other users). */
    showAllAssets: boolean = false;
    /** Indicates if the "Show All Assets" toggle is disabled. */
    isShowAllAssetsDisabled: boolean = false;
    /** Indicates if the paginator is disabled. */
    isPaginatorDisabled: boolean = false;

    startAfter$: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);
    listViewStartAfter: string | undefined = '.';
    /** Observable for the current pagination breakpoints. */
    currentPagination$: BehaviorSubject<DisplayModePagination[]> = new BehaviorSubject<DisplayModePagination[]>(
        BINS_PAGINATION_BREAKPOINTS.get(Breakpoint.LARGE) || []
    );
    folderUrlId: string | null = null;
    /** Account data */
    username: string = '';
    // persist clipbin multiselection
    itemMultiSelection = new Set<string>();
    lastSelectedIndex: number = 0;
    /* Add new action props */
    createActions: Map<OrganizerTypes, OrganizersActionOptions> = new Map([
        [
            OrganizerTypes.FOLDER,
            {
                name: OrganizerEnum.FOLDER,
                type: OrganizerTypes.FOLDER,
                allow: true,
                errorMsg: '',
                order: 0
            }
        ],
        [
            OrganizerTypes.CLIP_BIN,
            {
                name: OrganizerEnum.CLIP_BIN,
                type: OrganizerTypes.CLIP_BIN,
                allow: true,
                errorMsg: '',
                order: 1
            }
        ]
    ]);
    isRootPath: boolean = true;
    isMyFolder: boolean = false;
    currentFolderLevel: number = 0;
    currentOwner: ClipbinsOwner = ClipbinsOwner.USER;

    hasClipBinsLoaded$ = new BehaviorSubject<boolean>(false);

    protected isAdmin: boolean = false;
    protected userEmail: string = '';

    /* SearchResults */
    private searchChanged$: Observable<string | null>;
    private searchSubject = new Subject<string | null>();
    private readonly destroyed$ = new ReplaySubject<void>(1);

    constructor(
        private readonly binService: BinService,
        private readonly clipService: ClipApiService,
        private readonly cdr: ChangeDetectorRef,
        readonly searchInputService: SearchInputService,
        private authService: AuthService,
        readonly mediaCache: MediaCacheService,
        readonly scrubbingService: ScrubbingService,
        private readonly paginationService: PaginationService,
        private readonly breakpointObserver: BreakpointObserver,
        private readonly dialog: MatDialog,
        private readonly route: ActivatedRoute,
        private readonly featureService: FeatureFlagService,
        public  readonly resourceService: ResourceService,
        private readonly router: Router,
        public selectionService: SelectionService,
        private readonly routerExtensionService: RouterExtensionService,
        private readonly stateService: StateService
    ) {
        /**
         * Initialize component variables and set up subscriptions.
         */
        this.searchInputService.searchType$.next(SearchType.VOD);
        this.searchChanged$ = this.getSearchChanged();
        this.binsPagination = this.paginationService.getEmptyPagination();
        this.clipsPagination = this.paginationService.getEmptyPagination();
        this.startAfter = undefined;
        this.binService.displayMode$.next(DEFAULT_DISPLAY_MODE);

        this.binService.displayMode$.subscribe((mode) => {
            this.displayMode = mode ?? DEFAULT_DISPLAY_MODE;
        });

        // Get user's name
        const accountUserName = this.authService.getUserName();
        this.username = accountUserName.split(' ')[0] || 'User';

        this.isAdmin = this.authService.isAdmin;
        this.userEmail = this.authService.getUserEmail();
    }

    get startAfter(): string | undefined {
        return this.startAfter$.value || undefined;
    }

    // Pagination
    set startAfter(val: string | undefined) {
        this.startAfter$.next(val);
    }

    get binResults() {
        return this.resources$.value as Resource[];
    }

    get clipResults() {
        return this.resources$.value as Clip[];
    }

    get folderResults() {
        return this.resources$.value as Resource[];
    }

    get allChecked() {
        return this.resources$.value.length > 0 && this.itemMultiSelection.size === this.resources$.value.length;
    }

    get someChecked() {
        return (
            this.resources$.value.length > 0 &&
            this.itemMultiSelection.size > 0 &&
            this.itemMultiSelection.size < this.resources$.value.length
        );
    }

    /**
     * Fetches paginated resources (folders, bins, or clips) based on the
     * provided parameters.
     *
     * @param resourceType The type of resource to fetch.
     * @param owner The owner of the resources.
     * @param searchTerm The search term to filter resources.
     * @param pageIndex The page index to fetch.
     * @param pageSize The number of items per page.
     * @param startAfter The ID of the item to start after (for pagination).
     */
    getPaginatedResources(
        resourceType: ResourceType,
        owner: string,
        searchTerm: string,
        pageIndex?: number,
        pageSize?: number,
        startAfter?: string
    ) {
        this.controlsLocked$.next(true);
        const pSize = this.resourceService.BASE_LIMIT;
        const pIndex = pageIndex || 0;

        const paginatedValue: PaginationInfo = {
            limit: pSize,
            offset: pIndex
        };

        if (startAfter) paginatedValue.startAfter = startAfter;

        const searchOptions: SearchOptions = {
            searchTerm: searchTerm,
            owner: owner === 'current_user' ? this.authService.getUserEmail() : '',
            type: resourceType.name
        };

        const { isNestedFolder, parentId } = extractUrlDetails(this.router);
        if (!isNestedFolder && !searchTerm) searchOptions['level'] = 0;

        if (isNestedFolder && parentId && !this.hasASearchTerm() && this.folderContentComponent) {
            this.folderContentComponent.reloadFolderContent(this.searchText || '');
            this.resultsLoading = false;
            this.controlsLocked$.next(false);
            this.cdr.markForCheck();
        } else {
            this.resourceService
                .getResource(resourceType, paginatedValue, searchOptions)
                .pipe(takeUntil(this.destroyed$))
                .subscribe((res: ResourceResult) => {
                    const resourceResult = res as ResourceResult;
                    this.listViewStartAfter = resourceResult.clipBins
                        ? resourceResult.paginationData.startAfter
                        : (resourceResult.paginationData.totalPages > 1
                          ? '.'
                          : '');
                    this.resultsLoading = false;
                    this.controlsLocked$.next(false);
                    if (res) {
                        this.startAfter = res?.paginationData?.startAfter;
                    }
                    this.cdr.detectChanges();
                });
        }
    }

    ngOnInit(): void {
        this.route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe((params) => {
            if(params['viewMode']) {
                this.displayMode = params['viewMode'] === DisplayMode.LIST ? DisplayMode.LIST : DEFAULT_DISPLAY_MODE;
                this.binService.displayMode$.next(this.displayMode);
                this.cdr.markForCheck();
            }
        });

        this.updateGridIfCreateDeleteOrMove();
    }

    updateGridIfCreateDeleteOrMove() {
      this.stateService.returnToFirstPageOnGrid$
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (updated) => {
          if(updated) {
            this.pageIndex$.next(0);
            this.startAfter$.next(undefined);
          }
        }
      });
  }

    scrollToClipbinsSection(isPageChange: boolean = false) {
        if (this.isCBOContext() || isPageChange) {
            this.clipbinsSection?.nativeElement.scrollIntoView({ behavior: 'instant' });
        }
    }

    ngAfterViewInit(): void {
        this.scrollToClipbinsSection();

        // Resets pagination and scrolls top when search value changes or clip bins get updated
        this.listenForSearchAndPageChanges();
        this.startAfter$.pipe(takeUntil(this.destroyed$)).subscribe((startAfter) => {
            if (!this.paginators.length) return;
            if (this.searchModeSelected === 'bins') {
                this.updatePaginators(startAfter);
            }
        });

        this.updateResults();
        this.listenForNavigationChanges();

        this.resourceService.currentResources$.pipe(takeUntil(this.destroyed$)).subscribe((resources) => {
            this.resources$.next(resources as unknown as Resource[]);

            this.startAfter = this.resourceService.paginationInfo$.value?.startAfter || undefined;
            this.validateActionOptions();
            this.cdr.detectChanges();
            this.scrollToClipbinsSection();
        });

        this.resourceService.isLoading$.pipe(takeUntil(this.destroyed$)).subscribe((isLoading) => {
            this.resultsLoading = isLoading;
            this.cdr.detectChanges();
        });

        this.resourceService.paginationInfo$.pipe(takeUntil(this.destroyed$)).subscribe((pagination) => {
            if (pagination.paginationResult) {
                if (this.searchModeSelected === 'folders') {
                    this.foldersPagination.pageLimit = pagination.paginationResult.pageSize;
                    this.foldersPagination.totalCount = pagination.paginationResult.totalItems;
                    this.foldersPagination.pageIndex = pagination.offset;
                } else {
                    this.binsPagination.pageSize = pagination.limit;
                    this.binsPagination.pageIndex = pagination.offset;
                    this.binsPagination.totalCount = (pagination.paginationResult.startAfter || pagination.offset > 0) ?  UNKNOWN_LENGTH :  pagination.paginationResult.pageSize;
                  }
                  this.cdr.detectChanges();
            }
        });

        this.controlsLocked$.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
            this.isShowAllAssetsDisabled = value;
            this.isPaginatorDisabled = value;
        });


        this.stateService.isRecentVideosLoaded$.pipe(takeUntil(this.destroyed$)).subscribe((_) => {
            if(this.isCBOContext()) {
                this.scrollToClipbinsSection(true);
            }
        });
        this.resourceService.get<IResourceService>()
          .pipe(
            takeUntil(this.destroyed$),
          )
          .subscribe((res: IResourceService) => {
              this.toggleShowAllAssets(res?.showAll);
              this.resourceService.selectedSearchMode$.next(res?.searchMode === 'folders' ? BinSectionContent.FOLDER : BinSectionContent.BIN);
              this.searchModeSelected = res?.searchMode === 'folders' ? BinSectionContent.FOLDER : BinSectionContent.BIN;
          });
    }

    // Add a new search term as a chip
    addTerm(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        if (value) {
            this.searchTerms.push(value);
            event.chipInput?.clear(); // Clear the input field
            this.searchControl.setValue(''); // Reset FormControl value
            this.performSearch(); // Trigger search with new term
        } else {
            this.performSearch(); // Trigger search if "Enter" pressed with no text
        }
    }

    // Remove a search term
    removeTerm(index: number): void {
        this.searchTerms.splice(index, 1);
        this.performSearch(); // Update search after removal
    }

    // Clear all search terms and input
    onSearchClear(): void {
        this.searchTerms = [];
        this.searchControl.setValue('');
        this.performSearch(); // Trigger search with empty query
    }

    // Construct the current search query and perform the search
    performSearch(): void {
        const currentInput = this.searchControl.value || '';
        const query = [...this.searchTerms, currentInput].filter((term) => term.trim()).join(' ');
        this.searchText = query;
        this.searchSubject.next(query);
        this.cdr.markForCheck();
        if (this.searchInput) {
            this.searchInput.nativeElement.focus();
        }
    }

    updatePaginators(startAfter?: string): void {
        if (startAfter) {
            this.paginators.forEach((paginator) => {
                paginator['_nextButtonsDisabled'] = () => false;
                paginator.hasNextPage = () => true;
            });
        } else if (this.pageIndex$.value !== 0 || !this.resources$.value.length) {
            this.paginators.forEach((paginator) => {
                paginator['_nextButtonsDisabled'] = () => true;
                paginator.hasNextPage = () => false;
            });
        }
    }

    onFolderContentLoading(isLoading: boolean) {
        this.resultsLoading = isLoading;
        this.cdr.detectChanges();
    }

    listenForNavigationChanges() {
        combineLatest([this.route.url, this.route.queryParams])
            .pipe(takeUntil(this.destroyed$))
            .subscribe(([url]) => {
                this.resultsLoading = true;
                if (!url.length) {
                    this.isRootPath = true;
                }
                if (url[0]?.path === 'folders') {
                    this.resourceService.set({...this.resourceService.store$.value, searchMode: BinSectionContent.FOLDER});
                    this.folderUrlId = url[1]?.path;
                }
                this.resourceService.resetContext();
                this.resultsLoading = false;
                this.controlsLocked$.next(false);
                this.setIsShowAllDisabled();
            });
    }

    listenForSearchAndPageChanges() {
        merge(this.searchChanged$, this.resourceService.currentOwner)
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                this.resetPagination();
                this.cdr.markForCheck();
            });
    }

    searchClips(searchTerm: string | null) {
        if (!searchTerm) return EMPTY;

        return this.clipService.searchClipsByTitle(
            searchTerm,
            this.resourceService.BASE_LIMIT,
            this.clipsPagination.nextPageToken
        );
    }

    /**
     * Loads the initial bins list for the current owner and updates the results based on changes.
     */
    updateResults() {
        const otherObservables = [
            mapSearchEventStream<string | null>(this.searchChanged$, 'searchChanged'),
            mapSearchEventStream<string>(this.resourceService.currentOwner, 'currentOwner'),
            mapSearchEventStream<number>(this.pageIndex$, 'pageIndex'),
            mapSearchEventStream<BinSectionContentType | undefined | null>(
                this.resourceService.selectedSearchMode$,
                'searchMode'
            ),
            mapSearchEventStream<DisplayMode>(this.binService.displayMode$, 'displayMode')
        ];

        combineLatest([...otherObservables] as const)
            .pipe(debounceTime(SEARCH_DEBOUNCE), takeUntil(this.destroyed$))
            .subscribe((value) => {
                const [search, owner, pageIndex, searchMode, displayMode] = value as UpdateResultsTuple;

                if (this.folderUrlId && !this.hasASearchTerm()) {
                    return;
                }
                // Extract typed values
                const term = search.value || '';
                const ownerValue = owner.value;
                const pageIndexValue = pageIndex.value;
                const searchModeSelected = searchMode.value;
                const displayModeValue = displayMode.value;
                const searchOptions = this.resourceService.searchOptions$.value;
                const pageLimit = this.resourceService.BASE_LIMIT;

                // Set pagination sizes
                this.binsPagination.pageSize = pageLimit;
                this.clipsPagination.pageSize = pageLimit;
                this.foldersPagination.pageLimit = pageLimit;

                // Calculate newPageIndex
                let newPageIndex: number =
                        (term !== searchOptions.searchTerm || displayModeValue === DisplayMode.LIST) ? 0 : pageIndexValue;

                this.resultsLoading = true;
                this.cdr.detectChanges();

                if (this.searchModeSelected == 'bins') {
                  this.updatePaginators();
                }
                this.createActions.set(OrganizerTypes.FOLDER, {
                    name: OrganizerEnum.FOLDER,
                    type: OrganizerTypes.FOLDER,
                    allow: true,
                    errorMsg: '',
                    order: 0
                });

                this.hasClipBinsLoaded$.next(true);

                if (term !== searchOptions.searchTerm || displayModeValue === DisplayMode.LIST) {
                    this.startAfter = undefined;
                }

                if (searchModeSelected !== this.searchModeSelected) {
                    this.searchModeSelected = searchModeSelected ?? DEFAULT_SEARCH_MODE;
                    this.startAfter = undefined;
                    this.resourceService.set({
                        ...this.resourceService.store$.value,
                        searchMode: searchModeSelected
                    });
                    newPageIndex = 0;
                }

                this.showAllAssets = ownerValue !== 'current_user';

                // Update pagination
                switch (this.searchModeSelected) {
                    case BinSectionContent.CLIP:
                        this.clipsPagination.pageIndex = newPageIndex;
                        this.binsPagination.pageIndex = 0;
                        this.foldersPagination.pageIndex = 0;
                        break;
                    case BinSectionContent.BIN:
                        this.binsPagination.pageIndex = newPageIndex;
                        this.clipsPagination.pageIndex = 0;
                        this.foldersPagination.pageIndex = 0;
                        break;
                    case BinSectionContent.FOLDER:
                        this.foldersPagination.lastCursor = { ...this.foldersPagination.nextCursor } as Resource;
                        this.foldersPagination.nextCursor = this.folderResults[this.folderResults.length - 1];
                        this.foldersPagination.pageIndex = newPageIndex;
                        this.clipsPagination.pageIndex = 0;
                        this.binsPagination.pageIndex = 0;
                        break;
                }


                this.validateActionOptions();

                this.getPaginatedResources(
                    searchModeSelected === BinSectionContent.FOLDER ? ResourceTypes.FOLDER : ResourceTypes.CLIPBIN,
                    ownerValue,
                    term,
                    newPageIndex,
                    pageLimit,
                    this.startAfter
                );
            });
    }

    getPlaceholderText() {
        if (!this.searchModeSelected) return '';
        switch (this.searchModeSelected) {
            case BinSectionContent.FOLDER:
                return 'Search folders';
            case BinSectionContent.BIN:
                return 'Search clip bins';
            case BinSectionContent.CLIP:
                return 'Search clips';
        }
    }

    formatBinSectionContent(mode: BinSectionContentType) {
        switch (mode) {
            case BinSectionContent.FOLDER:
                return 'Folders';
            case BinSectionContent.BIN:
                return 'Clip bins';
            case BinSectionContent.CLIP:
                return 'Clips';
        }
    }

    handleSearchModeChange(mode: BinSectionContentType) {
        this.resultsLoading = true;
        const { isNestedFolder } = extractUrlDetails(this.router);
        //TODO: Fix logic if we ever have more than 2 modes
        if (isNestedFolder && mode === BinSectionContent.BIN) {
            this.resourceService.set({ ...this.resourceService.store$.value, searchMode: mode });
            this.folderUrlId = null;
            this.router.navigate(['/']);
        }

        if (this.searchModeSelected !== mode) {
            this.pageIndex$.next(0);
        }
        this.setIsShowAllDisabled();
        this.itemMultiSelection.clear();
        this.resourceService.selectedSearchMode$.next(mode);
    }

    setIsShowAllDisabled() {
        this.isShowAllAssetsDisabled = !!this.folderUrlId || this.searchModeSelected === BinSectionContent.CLIP;
    }

    /* Handles the toggling of clip bins visualization with or without direct 'show all' event */
    toggleShowAllAssets(toggle: boolean | null = null): void {
        toggle == null ? (this.showAllAssets = !this.showAllAssets) : (this.showAllAssets = !!toggle);
        if (toggle == null) {
            this.showAllAssets = false;
        }
        this.currentOwner = this.showAllAssets ? ClipbinsOwner.ALL : ClipbinsOwner.USER;
        this.resourceService.currentOwner.next(this.showAllAssets ? ClipbinsOwner.ALL : ClipbinsOwner.USER);
        this.startAfter = undefined;
        this.resourceService.set({ ...this.resourceService.store$.value, showAll: toggle });
        this.pageIndex$.next(0);
    }

    /** Toggles between grid and list view */
    toggleViewMode() {
        const nextMode = this.isGrid(this.displayMode) ? DisplayMode.LIST : DEFAULT_DISPLAY_MODE;

        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { viewMode: nextMode },
            queryParamsHandling: 'merge'
        });

        this.displayMode = nextMode;
        this.binService.displayMode$.next(nextMode);
        this.startAfter = undefined;

        // Reset pagination to adjust to new view mode
        this.pageIndex$.next(0);
        this.binsPagination.pageSize = this.resourceService.BASE_LIMIT;
        this.clipsPagination.pageSize = this.resourceService.BASE_LIMIT;
    }

    isGrid(displayMode: DisplayMode) {
        return displayMode === DEFAULT_DISPLAY_MODE;
    }

    onPageChange(event: PageEvent) {
        this.resultsLoading = true;
        this.cdr.markForCheck();

        const urlTree = this.router.parseUrl(this.router.url);
        const isNestedFolder = urlTree.root.children['primary']?.segments[0]?.path === 'folders';

        const res = isNestedFolder
            ? this.resourceService.currentContext$.value.parent.children
            : this.resourceService.currentResources$.value;


        if (event.pageIndex !== 0) {
            if (event.previousPageIndex != null && event.pageIndex > event.previousPageIndex) {
                this.startAfter =
                    this.searchModeSelected === 'folders'
                        ? (res[res.length - 1].id as string)
                        : this.resourceService.paginationInfo$.value?.startAfter || '';
            } else {
                const lastResource =
                    this.searchModeSelected === 'folders'
                        ? this.resourceService.getLastResourceByPage(event.pageIndex - 1)
                        : this.resourceService.getLastBinResourceByPage(event.pageIndex - 1);
                if (lastResource) this.startAfter = lastResource.resourceId as string;
            }
        } else {
            this.startAfter = undefined;
        }

        this.scrollToClipbinsSection(true);
        this.itemMultiSelection.clear();
        this.paginators.forEach((paginator) => {
            //sync data between paginators
            paginator.pageIndex = event.pageIndex;
            paginator.pageSize = event.pageSize;
        });

        this.pageIndex$.next(event.pageIndex);
    }

    /**
     * Validates the available actions for creating resources (folders, clip bins)
     * based on the current resource context (ownership, nesting level, and content).
     * Ensures folder creation respects a maximum nesting level of 2.
     * Disables actions when browsing another user's folder or exceeding nesting limits.
     */
    validateActionOptions(): void {
        this.resourceService.currentContext$.pipe(takeUntil(this.destroyed$)).subscribe((context) => {
            const parent = context?.parent;

            this.isRootPath = !extractUrlDetails(this.router).isNestedFolder;
            this.isMyFolder = parent?.owner === this.authService.getUserEmail();
            this.currentFolderLevel = parent?.level as number;

            // If no parent is found the level is root and all actions are allowed
            if (
                !parent ||
                this.isRootPath ||
                (!context?.parent.children.length && this.isMyFolder && this.currentFolderLevel < 2)
            ) {
                this.createActions.forEach((v, k, map) => map.set(k, { ...v, allow: true }));
                return;
            }

            // If not my folder all actions are disabled
            if (!this.isMyFolder) {
                this.createActions.forEach((v, k, map) => map.set(k, { ...v, allow: false }));
                return;
            }

            // Validate if action is allowed or not and if there are any error message
            this.createActions.forEach((v, k, map) => {
                if (['localupload', 'cloudingest'].includes(k)) return;
                const isCurrentFolderPath = context.parent?.children.some(
                    (value) => value.type === OrganizerTypes.FOLDER
                );

                const allowValue = Boolean(this.currentFolderLevel < 2 && isCurrentFolderPath);
                const errorMsg = actionOptionErrorMessage(isCurrentFolderPath, v.type, this.currentFolderLevel);
                map.set(k, {
                    ...v,
                    allow: k === OrganizerTypes.FOLDER ? allowValue : !isCurrentFolderPath,
                    errorMsg
                });
            });

            this.cdr.markForCheck();
        });
    }

    /**
     * Toggles manual selection of a clip bin for bulk operation. If the "shift" key
     * is pressed, all clips between the last selected one and the current clicked
     * one will be toggled.
     */
    toggleSelection(event: MouseEvent, bin: Bin) {
        const currentIndex = (this.resources$.value as Bin[]).findIndex((b) => b.name === bin.name);

        if (event.shiftKey && this.lastSelectedIndex !== null) {
            const start = Math.min(this.lastSelectedIndex, currentIndex);
            const end = Math.max(this.lastSelectedIndex, currentIndex);
            for (let i = start; i <= end; i++) {
                this.itemMultiSelection.add((this.resources$.value[i] as Bin).name);
            }
        } else {
            if (this.itemMultiSelection.has(bin.name)) {
                this.itemMultiSelection.delete(bin.name);
            } else {
                this.itemMultiSelection.add(bin.name);
            }
            this.lastSelectedIndex = currentIndex;
        }
        this.cdr.markForCheck();
    }

    toggleSelectAll() {
        if (this.allChecked && this.itemMultiSelection.size > 0) {
            this.itemMultiSelection.clear();
        } else {
            if (this.searchModeSelected == BinSectionContent.BIN) {
                this.resources$.value.map((bin) => this.itemMultiSelection.add((bin as Bin).name));
            }
        }
        this.cdr.markForCheck();
    }

    deleteSelection() {
        this.dialog.open(DeleteMultipleBinDialog, {
            ...DeleteMultipleBinDialog.dialogOptions,
            data: {
                bins: this.itemMultiSelection,
                onConfirm: () => {
                    this.pageIndex$.next(0);
                },
                onOpen: () => {
                    this.cdr.detectChanges();
                    this.itemMultiSelection = new Set();
                },
                onCancel: () => {
                    this.cdr.detectChanges();
                }
            }
        });
    }

    executeCreateAction(job: OrganizerTypes) {
        switch (job) {
            case OrganizerTypes.CLIP_BIN:
                this.dialog
                    .open(CreateBinDialog, {
                        ...CreateBinDialog.dialogOptions,
                        data: { parent: this.resourceService.currentContext$.value?.parent }
                    })
                    .afterClosed()
                    .pipe(takeUntil(this.destroyed$))
                    .subscribe();
                break;
            case OrganizerTypes.FOLDER:
                this.dialog
                    .open(ClipbinFolderCreationDialog)
                    .afterClosed()
                    .pipe(takeUntil(this.destroyed$))
                    .subscribe();
                break;
        }
    }

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    // --------------------- bulk actions

    handleBulkDelete(selectedItems: Set<ResultItem>): void {
        const folders: Resource[] = [];
        const bins: Resource[] = [];

        selectedItems.forEach((item) => {
            if (this.isResource(item)) {
                if (item.type === 'folder') {
                    folders.push(item);
                } else if (item.type === 'clipbin') {
                    bins.push(item);
                }
            }
        });

        if (folders.length > 0) {
            this.openDeleteFolders(new Set(folders));
        }

        if (bins.length > 0) {
            this.openDeleteBins(bins);
        }
    }

    handleBulkMove(selectedItems: Set<ResultItem>): void {
        const folders: Resource[] = [];
        const bins: Resource[] = [];

        selectedItems.forEach((item) => {
            if (this.isResource(item)) {
                if (item.type === 'folder') {
                    folders.push(item);
                } else if (item.type === 'clipbin') {
                    bins.push(item);
                }
            }
        });
        if (folders.length > 0) {
            this.moveFoldersToFolder(new Set(folders));
        }
        if (bins.length > 0) {
            this.moveBinsToFolder(bins);
        }
    }

    isResource(obj: ResultItem): obj is Resource {
        return 'type' in obj && typeof obj.type === 'string';
    }

    openDeleteFolders = (folders?: Set<ResultItem>) => {
        if (folders) {
            const folders_ = Array.from(folders);
            this.dialog.open(ClipBinFolderDeleteDialog, {
                ...ClipBinFolderDeleteDialog.dialogOptions,
                data: folders_
            });
        }
    };

    openDeleteBins = (bins: Resource[]) => {
        this.dialog
            .open<DeleteBinDialog, DeleteBinDialogData>(DeleteBinDialog, {
                ...DeleteBinDialog.dialogOptions,
                data: { resources: bins }
            })
            .afterClosed()
            .pipe(takeUntil(this.destroyed$));
    };

    moveFoldersToFolder = (folders?: Set<ResultItem>) => {
        let folders_: ResultItem[] = [];
        if (folders) {
            folders_ = Array.from(folders);
        }
        this.dialog.open(ClipBinFolderMoveDialog, {
            ...ClipBinFolderMoveDialog.dialogOptions,
            data: folders_
        });
    };

    moveBinsToFolder = (bins: Resource[]) => {
        bins.forEach((bin) => (bin['displayMode'] = 'list'));
        this.dialog.open(ClipBinBinMoveDialog, {
            ...ClipBinBinMoveDialog.dialogOptions,
            data: bins
        });
    };

    toggleSelect(resource: ResultItem) {
        this.selectionService.toggleSelect(resource);
    }

    toggleAllSelect(checked: boolean) {
        this.selectionService.setSelectAll(checked);
    }

    setShowAll(checked: boolean) {
        if (this.routerExtensionService.getCurrentUrl().includes('folders')) {
            this.router.navigate(['/']);
        }
        this.toggleShowAllAssets(checked);
        this.itemMultiSelection.clear();
        this.selectionService.setSelectAll(false);
        this.cdr.detectChanges();
    }

    private hasASearchTerm(): boolean {
        return !!this.searchText && this.searchText.trim() !== '';
    }

    private resetPagination() {
        if (this.searchText) {
            this.pageIndex$.next(0);
        }

        const pageLimit = this.resourceService.BASE_LIMIT;
        this.binsPagination = this.paginationService.getEmptyPagination(pageLimit);
        this.clipsPagination = this.paginationService.getEmptyPagination(pageLimit);
        this.foldersPagination = {
            pageLimit,
            nextCursor: null,
            lastCursor: null,
            pageIndex: 0,
            totalCount: 0
        };
        this.cdr.detectChanges();
    }

    private getSearchChanged() {
        return this.searchSubject.pipe(
            map((value) => {
                if (!value || !value.length) {
                    this.searchText = null;
                    return null;
                }
                this.searchText = value;
                this.searchInputService.partialQuery$.next(value);
                this.itemMultiSelection.clear();
                return value;
            }),
            debounceTime(SEARCH_DEBOUNCE),
            startWith('')
        );
    }

    /**
     * Checks if the current navigation context is within the Clip Bin/Folder Organization (CBO) section
     * by examining the previous and current URLs.
     * This helps determine if the user is navigating within the clip bin organization context
     * versus coming from another part of the application.
     *
     */
    private isCBOContext(): boolean {
        const previousUrl = this.routerExtensionService.getPreviousUrl();
        const currentUrl = this.routerExtensionService.getCurrentUrl();
        return (
            ((previousUrl?.includes('folder') || previousUrl?.includes('clipbin')) || currentUrl.includes('folder')) ??
            false
        );
    }
}
