import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnDestroy,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BehaviorSubject,
  catchError,
  map,
  Observable,
  of,
  ReplaySubject,
  takeUntil,
  tap,
} from 'rxjs';


import { AuthService } from '../../auth/auth_service';
import { Clip } from '../../services/asset_service';
import { Bin, BinWithClips } from '../../services/bin.service';
import { DisplayMode } from '../../services/vod_search_service';
import { ClipBinBinMoveDialog } from '../../shared/clipbin_bin_move_dialog/clipbin_bin_move_dialog';
import { ClipBinFolderDeleteDialog } from '../../shared/clipbin_folder_delete_dialog/clipbin_folder_delete_dialog';
import { ClipBinFolderMoveDialog } from '../../shared/clipbin_folder_move_dialog/clipbin_folder_move_dialog';
import { ClipBinFolderUpdateDialog } from '../../shared/clipbin_folder_update_dialog/clipbin_folder_update_dialog';
import {
  DeleteBinDialog,
} from '../../shared/delete_bin_dialog';
import { RenameBinDialog } from '../../shared/rename_bin_dialog';
import { ResourceBin } from '../clip-bin-preview/clip-bin-preview';
import { DialogOpener } from '../clip-bin-section/service/dialog-opener.service';
import {
  ResourceTypeApiName,
  ResourceTypes,
} from '../clip-bin-section/service/resource-types';
import {
  ParentResource,
  Resource,
  ResourceContent,
  ResourceResult,
  ResourceService,
} from '../clip-bin-section/service/resource.service';
import { SelectionService } from '../clip-bin-section/service/selection.service';

type ResultItem = Resource | Bin | Clip;

interface ExpandedRow {
  id: string;
  element: ResultItem;
  detailRow: boolean;
  expanded$: BehaviorSubject<boolean>;
  isLoading$: BehaviorSubject<boolean>;
}

@Component({
  selector: 'mam-cbo-list-display',
  styleUrl: './cbo-list-display.component.scss',
  templateUrl: './cbo-list-display.component.html',
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', visibility: 'hidden' })
      ),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CboListDisplayComponent implements OnDestroy, AfterViewInit {

  resourceService = inject(ResourceService);
  selectionService = inject(SelectionService);

  @Input() result!: ResultItem[];
  @Input() selection!: Set<unknown>;
  @Input() isSubRow: boolean = false;
  @Input() isNextTable: boolean = false;
  @Input() parentId: string = '';
  @Input() owner:string = '';
  private readonly destroyed$ = new ReplaySubject<void>(1);
  @Input() isNested:boolean = false;
  @Input() startAfter:string = '';
  @Input() offset:number = 0;
  @Input() isAdmin: boolean = false;
  @Input() isShowAll: boolean = false;

  firstItem: ResultItem | undefined = undefined;

  displayedColumns: string[] = ['select','name', 'content', 'owner', 'menu'];
  isDisplayingBin: boolean = false;

  showLoadMoreButton: boolean = false;

  dataSource$: Observable<ExpandedRow[]> = new Observable();
  dataSource: ExpandedRow[] = [];

  expandedFolderDataCache: { [folderId: string]: ResultItem[] } = {};

  resourceContent: ResultItem[] | undefined = undefined;
  lastResultId:string = '';

  isLoadingMore: boolean = false;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private readonly cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private admin: AuthService,
    private dialogOpenerService: DialogOpener
  ) {}

  ngAfterViewInit() {
    this.getIsAdmin();
    this.resourceService.currentResources$.pipe(takeUntil(this.destroyed$)).subscribe(_ => {
      this.createDataSource(this.result);
      this.cdr.detectChanges();
    });
  }

  createDataSource(result: ResultItem[]) {
    this.expandedFolderDataCache = {};
    this.dataSource$ = this.createDataSource$(result).pipe(
    tap(expandedRows => {
          this.dataSource = expandedRows;
          this.dataSource.forEach(dataItem => {
            this.selectionService.registerRow(dataItem.element);
        });
          this.cdr.markForCheck();
      })
    );
    this.showLoadMoreButton = this.startAfter !== '';
    if (result && result.length > 0) {
        this.firstItem = result[0];
        if (this.isResource(this.firstItem)) {
            this.isDisplayingBin = this.firstItem.type === 'clipbin';
        } else if (this.isBin(this.firstItem)) {
            this.isDisplayingBin = true;
        }

        const lastResult = result[result.length - 1];

        if (this.isResource(lastResult)) {
            this.lastResultId = lastResult.id || '';
        } else {
            //TODO better error handling
        }

        if (this.isDisplayingBin)
            this.displayedColumns = this.displayedColumns.filter((column) => column !== 'owner');
        this.displayedColumns = this.owner ? this.displayedColumns.filter((column) => column !== 'owner') : this.displayedColumns;
    }

    if(!this.isAdmin && this.isShowAll) this.displayedColumns = this.displayedColumns.filter((column) => column !== 'select');

    if(result.length < 12) {
      this.showLoadMoreButton = false;
    }
    this.cdr.markForCheck();
  }

  getStyles(): { [key: string]: string } {
    if (this.isDisplayingBin) {
      if (!this.isSubRow) {
        return { 'padding-left': '8px' };
      }

      if (this.firstItem?.level) {
        const calculatedPadding = Math.round(Math.pow(this.firstItem.level * 2, 1.8) + 50);
        return { 'padding-left': `${calculatedPadding}px` };
      }
    }

    return this.firstItem?.level
      ? { 'padding-left': `${Math.pow(this.firstItem.level * 2, 2)}px` }
      : { 'padding-left': '0' };
  }

  isChildSelected(row: ExpandedRow): boolean {
    return this.selectionService.childSelection().has(row.element);
  }

  getAssetCount(resource: (Resource | ParentResource)) {
    if(this.isParentResource(resource) && resource.children.length === 0 ) return 0;
    if(resource.directChildrenCount && resource.directChildrenCount > 0) return resource.directChildrenCount;
    else if (resource.iasData && Number.parseInt(resource.iasData.assetCount) > 0) return Number.parseInt(resource.iasData.assetCount);
    else  return 0;
  }

  createDataSource$(items: ResultItem[]): Observable<ExpandedRow[]> {
    return new BehaviorSubject(items).pipe(
      map((resultItems) =>
        resultItems.map((item) => {
          const id = this.getItemId(item);
          return {
            id,
            element: item,
            detailRow: false,
            expanded$: new BehaviorSubject<boolean>(false),
            isLoading$: new BehaviorSubject<boolean>(false),
          };
        })
      ),
      tap((expandedRows) =>
        expandedRows.forEach((row) => this.setupRowExpansionListener(row))
      )
    );
  }
  getItemId(item: ResultItem): string {
    return (this.isResource(item) && item.id) ? item.id : `item-${crypto.randomUUID()}`;
  }

  setupRowExpansionListener(row: ExpandedRow): void {
    row.expanded$.pipe(takeUntil(this.destroyed$)).subscribe((isExpanded) => {
      if (
        isExpanded &&
        this.isResource(row.element) &&
        row.element.directChildrenCount &&
        !this.expandedFolderDataCache[row.id]
      ) {
        row.isLoading$.next(true);
        this.fetchFolderData(row);
      }
    });
  }

  toggleExpansion(row: ExpandedRow): void {
    const currentExpansionState = row.expanded$.value;
    row.expanded$.next(!currentExpansionState);
  }

  fetchFolderData(row: ExpandedRow): void {
    const folderId = row.id;
    this.resourceService
      .getResourceChildren(ResourceTypes.FOLDER, folderId, {
        limit: this.resourceService.BASE_LIMIT,
        offset: this.offset
      }, false)
      .pipe(
        takeUntil(this.destroyed$),
        catchError((error) => {
          console.error('Error fetching folder data:', error);
          row.isLoading$.next(false);
          return of(null);
        })
      )
      .subscribe((resourceContent: ResourceContent | null) => {
        if (resourceContent) {
          this.expandedFolderDataCache[folderId] = resourceContent.parent.children;
          this.startAfter = resourceContent.paginationData?.totalPages === 1 ? '' : '.';
          row.isLoading$.next(false);
          this.cdr.markForCheck();
        }
      });
  }

  trackById(index: number, item: ExpandedRow): string {
    return item.id;
  }

  isResource(obj: ResultItem): obj is Resource {
    return 'type' in obj && typeof obj.type === 'string';
  }

  isBin(obj: ResultItem): obj is Bin {
    return 'type' in obj && typeof obj.type === 'string';
  }

  isClip(obj: ResultItem): obj is Clip {
    return 'type' in obj && typeof obj.type === 'string';
  }

  isParentResource(resource: Resource | ParentResource): resource is ParentResource {
    return 'children' in resource;
  }

  get resourceResult(): Resource[] {
    return this.result as Resource[];
  }

  toggleSelect(row: ExpandedRow) {
    this.selectionService.toggleSelect(row.element);
  }

  toggleAllSelect(checked: boolean) {
      this.selectionService.setSelectAll(checked);
  }

  executeAction(action: string, resource: Resource | Bin | Clip) {
    if (!this.isResource(resource)) {
      return;
    }
    if (!resource.type || !this.ACTIONS_TARGET[resource.type]) {
      return;
    }
    this.ACTIONS_TARGET[resource.type][action](resource);
  }

  openShareBin(binValue: BinWithClips | Bin | Resource | ResourceBin) {
    this.resourceService.openShareBin(binValue);
  }

  moveBinToFolder = (bin: Resource) => {
    bin['displayMode'] = 'list';
    this.dialogOpenerService.openDialog(
      ClipBinBinMoveDialog,
      ClipBinBinMoveDialog.dialogOptions,
      bin,
      (result) => {
        if (result && result.parent && result.parent.children) {
          this.createDataSource(result.parent.children);
        }
      }
    );
  };

  openDeleteBin = (bin: Resource) => {
    this.dialogOpenerService.openDialog(
      DeleteBinDialog,
      DeleteBinDialog.dialogOptions,
      { resource: bin },
      (result) => {
        if (result && result.parent && result.parent.children) {
          this.createDataSource(result.parent.children);
        }
      }
    );
  };

  moveBinsToFolder = (bins: Resource[]) => {
    bins.forEach((bin) => (bin['displayMode'] = 'list'));
    this.dialogOpenerService.openDialog(
      ClipBinBinMoveDialog,
      ClipBinBinMoveDialog.dialogOptions,
      bins,
      (result) => {
        if (result && result.parent && result.parent.children) {
          this.createDataSource(result.parent.children);
        }
      }
    );
  };

  openDeleteBins = (bins: Resource[]) => {
    this.dialogOpenerService.openDialog(
      DeleteBinDialog,
      DeleteBinDialog.dialogOptions,
      { resources: bins },
      (result) => {
        if (result && result.parent && result.parent.children) {
          this.createDataSource(result.parent.children);
        }
      }
    );
  };

  moveFolderToFolder = (folder?: Resource) => {
    if (folder) folder['displayMode'] = 'list';
    this.dialogOpenerService.openDialog(
      ClipBinFolderMoveDialog,
      ClipBinFolderMoveDialog.dialogOptions,
      folder,
      (result) => {
        if (result && result.parent && result.parent.children) {
          this.createDataSource(result.parent.children);
        }
      }
    );
  };

  moveFoldersToFolder = (folders?: Set<ResultItem>) => {
    let folders_: ResultItem[] = [];
    if (folders) {
      folders_ = Array.from(folders);
    }
    this.dialogOpenerService.openDialog(
      ClipBinFolderMoveDialog,
      ClipBinFolderMoveDialog.dialogOptions,
      folders_,
      (result) => {
        if (result && result.parent && result.parent.children) {
          this.createDataSource(result.parent.children);
        }
      }
    );
  };

  openDeleteFolder = (folder?: Resource) => {
    this.dialogOpenerService.openDialog(
      ClipBinFolderDeleteDialog,
      ClipBinFolderDeleteDialog.dialogOptions,
      folder,
      (result) => {
        if (result && result.parent && result.parent.children) {
          this.createDataSource(result.parent.children);
        }
      }
    );
  };

  openDeleteFolders = (folders?: Set<ResultItem>) => {
    if (folders) {
      const folders_ = Array.from(folders);
      this.dialogOpenerService.openDialog(
        ClipBinFolderDeleteDialog,
        ClipBinFolderDeleteDialog.dialogOptions,
        folders_,
        (result) => {
          if (result && result.parent && result.parent.children) {
            this.createDataSource(result.parent.children);
          }
        }
      );
    }
  };

  openRenameBin = (bin: Resource) => {
    this.dialog
      .open(RenameBinDialog, {
        ...RenameBinDialog.dialogOptions,
        data: { title: bin.name, name: bin.iasId },
      })
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((newTitle) => {
        if (!newTitle) {
          return;
        }

        if ('displayName' in bin || bin['iasData'] && bin['iasData']['label'] && bin['iasData']['label']['displayName']) {
          const idx = this.result.findIndex(it => (it as Resource).iasId === bin.iasId);

          if(idx !== -1) {
            (this.result[idx] as Resource).name = newTitle;
            (this.result[idx] as Resource).displayName = newTitle;
            (this.result[idx] as Resource).iasData['label']['displayName'] = newTitle;
          }
        }
        this.cdr.detectChanges();
      });
  };

  openRenameFolder = (folder: Resource) => {
    this.dialog.open(ClipBinFolderUpdateDialog, {
      ...ClipBinFolderUpdateDialog.dialogOptions,
      data: folder,
    })
    .afterClosed()
    .pipe(takeUntil(this.destroyed$))
    .subscribe((newTitle) => {
      if (!newTitle) {
        return;
      }

      if ('displayName' in folder || folder['iasData'] && folder['iasData']['label'] && folder['iasData']['label']['displayName']) {
        const idx = this.result.findIndex(it => (it as Resource).iasId === folder.iasId);

        if(idx !== -1) {
          (this.result[idx] as Resource).name = newTitle;
          (this.result[idx] as Resource).displayName = newTitle;
          (this.result[idx] as Resource).iasData['label']['displayName'] = newTitle;
        }
      }
      this.cdr.detectChanges();
    });
  };

  navigateToFolder = (folder: Resource) => {
    if (folder.id) {
      const currentQueryParams = { ...this.route.snapshot.queryParams };
      const queryParams = { ...currentQueryParams, viewMode: DisplayMode.LIST };
      this.router.navigate(['/folders', folder.id], {
        queryParams,
        queryParamsHandling: 'merge',
      });
    }
  };

  navigateToClipBin = (bin: Resource) => {
    if (bin.iasId) {
      const currentQueryParams = { ...this.route.snapshot.queryParams };
      const queryParams = { ...currentQueryParams, viewMode: DisplayMode.LIST };

      this.router.navigate(['/clipbin', bin.iasId, 'clip', 0], {
        queryParamsHandling: 'merge',
        queryParams: { ...queryParams, type: 'bin' },
      });
    }
  };

  private ACTIONS_TARGET: Record<
    ResourceTypeApiName,
    { [action: string]: (resource: Resource) => void }
  > = {
    folder: {
      move: this.moveFolderToFolder,
      rename: this.openRenameFolder,
      delete: this.openDeleteFolder,
      navigate: this.navigateToFolder,
    },
    clipbin: {
      delete: this.openDeleteBin,
      move: this.moveBinToFolder,
      rename: this.openRenameBin,
      navigate: this.navigateToClipBin,
    },
    clip: {
      share: this.openShareBin,
    },
    asset: {
      share: this.openShareBin,
    },
  };

  onLoadMore() {
    this.isLoadingMore = true;
    const offset = this.offset;
    const startAfter = this.isDisplayingBin ? this.startAfter : this.lastResultId;
    const resourceType = this.isDisplayingBin ? ResourceTypes.CLIPBIN : ResourceTypes.FOLDER;

    if(this.isSubRow || this.isNested) {
      this.resourceService
      .getResourceChildren(ResourceTypes.FOLDER, this.parentId ,{
        limit: this.resourceService.BASE_LIMIT,
        offset,
        startAfter:this.lastResultId
      }, false)
      .pipe(
        takeUntil(this.destroyed$),
        catchError((error) => {
          console.error('Error fetching more data:', error);
          return of(null);
        })
      )
      .subscribe((resourceContent: ResourceContent | null) => {
        if (resourceContent) {
          this.offset++;
          this.resourceContent = resourceContent.parent.children;
          if (resourceContent.paginationData?.totalPages === this.offset + 1) {
            this.startAfter = '';
          }
          this.cdr.markForCheck();
        }
      });
    } else {
      this.resourceService
      .getResource(resourceType, {
        limit: this.resourceService.BASE_LIMIT,
        offset,
        startAfter,
      }, {owner:this.owner, searchTerm:'', level:0}, false, false)
      .pipe(
        takeUntil(this.destroyed$),
        catchError((error) => {
          console.error('Error fetching more data:', error);
          return of(null);
        })
      )
      .subscribe((resourceContent: unknown) => {
        const content = resourceContent as ResourceResult | null;
        if (content) {
          this.resourceContent = content.folders || content.clipBins;
          this.offset += 1;
          this.startAfter = (content.paginationData.totalPages === this.offset + 1) ? '' : (content.paginationData?.startAfter ?? this.startAfter);
          this.cdr.markForCheck();
        }
      });
    }
  }

  handleBulkDelete(selectedItems: Set<ResultItem>): void {
    const folders: Resource[] = [];
    const bins: Resource[] = [];

    selectedItems.forEach((item) => {
      if (this.isResource(item)) {
        if (item.type === 'folder') {
          folders.push(item);
        } else if (item.type === 'clipbin') {
          bins.push(item);
        }
      }
    });

    if (folders.length > 0) {
      this.openDeleteFolders(new Set(folders));
    }

    if (bins.length > 0) {
      this.openDeleteBins(bins);
    }
  }

  handleBulkMove(selectedItems: Set<ResultItem>): void {
    const folders: Resource[] = [];
    const bins: Resource[] = [];

    selectedItems.forEach((item) => {
      if (this.isResource(item)) {
        if (item.type === 'folder') {
          folders.push(item);
        } else if (item.type === 'clipbin') {
          bins.push(item);
        }
      }
    });
    if (folders.length > 0) {
      this.moveFoldersToFolder(new Set(folders));
    }
    if(bins.length > 0){
      this.moveBinsToFolder(bins);
    }
  }

  getIsAdmin() {
    if(this.admin.isAdmin) this.isAdmin = this.admin.isAdmin;
    return this.admin.isAdmin;
  }

  ngOnDestroy() {

    const rows: ResultItem[] = [];
    this.dataSource.forEach(row => rows.push(row.element));

    this.selectionService.unregisterRow(rows);
    this.destroyed$.next();
    this.destroyed$.complete();
  }


  isClipbin(value: BinWithClips | Bin | Resource | ResourceBin): boolean {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (value as any)?.type === 'clipbin';
  }

}
