import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { concatMap, EMPTY, map, of, ReplaySubject, takeUntil, tap } from 'rxjs';

import { assertTruthy } from '../../asserts/asserts';
import { ErrorResponse } from '../../error_service/error_response';
import { FeatureFlagService } from '../../feature_flag/feature_flag_service';
import { AssetService, Clip, ListResponse } from '../../services/asset_service';
import { Bin, BinWithClips } from '../../services/bin.service';
import { SnackBarService } from '../../services/snackbar_service';
import { DisplayMode } from '../../services/vod_search_service';
import { ClipBinBinMoveDialog } from '../../shared/clipbin_bin_move_dialog/clipbin_bin_move_dialog';
import { DeleteBinDialog, DeleteBinDialogData } from '../../shared/delete_bin_dialog';
import { RenameBinDialog } from '../../shared/rename_bin_dialog';
import { SharedLinkClipBinService } from '../../shared_clipbin/services/shared_link_clipbin.service';
import { ResourceTypes } from '../clip-bin-section/service/resource-types';
import { Resource, ResourceService } from '../clip-bin-section/service/resource.service';
import { SelectionService } from '../clip-bin-section/service/selection.service';
import { ContentEvent } from '../folder-content/folder-content.component';

type ResultItem = Resource | Bin | Clip;
export interface ResourceBin extends Resource, BinWithClips {
}

/**
 * Clickable clip bin card with up to 3 clips previews.
 */
@Component({
  selector: 'mam-clip-bin-preview',
  templateUrl: './clip-bin-preview.ng.html',
  styleUrls: ['./clip-bin-preview.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClipBinPreview implements OnInit, OnDestroy {
  @Input() bin!: BinWithClips | Bin | Resource | ResourceBin;

  // toggles between grid and list view
  @Input() binsViewMode: DisplayMode = DisplayMode.GRID;

  //toggles between active and inactive in a selection
  @Input() active: boolean = false;

  @Input() isAdmin: boolean = false;
  showAllBins: boolean = false;
  @Input() isMyFolder: boolean | undefined = undefined;

  @Output() contentEvent = new EventEmitter<ContentEvent>();

  @Input() sharingAdditionalProperties?: Record<string, string>;

  @Input() checkboxPresent: boolean = false;
  clips: Clip[] = [];
  /** True when layout switches to SMALL. */
  isSmallScreen = false;
  /** True when layout switches to MID. */
  isMidScreen = false;
  /** True when layout switches to Large. */
  isLargeScreen = false;
  /** True when layout switches to SMALL Landscape mode. */
  isSmallScreenLandScape = false;
  isLocationExpanded: boolean = false;
  private readonly destroyed$ = new ReplaySubject<void>(1);

  constructor(
    private readonly dialog: MatDialog,
    private readonly cdr: ChangeDetectorRef,
    private readonly assetService: AssetService,
    readonly featureService: FeatureFlagService,
    private readonly resourceService: ResourceService,
    private readonly router: Router,
    private selectionService: SelectionService,
    private snackBar: SnackBarService,
    private readonly sharedLinkClipBinService: SharedLinkClipBinService,
  ) {}

  get binValue(): BinWithClips | Bin | Resource | ResourceBin {
    return this.bin;
  }

  get binWithClips(): BinWithClips {
    const bin = this.bin as BinWithClips;

    if (this.clips.length > 0) {
      bin.clips = this.clips;
    }

    return bin;
  }

  isResourceBin(bin: BinWithClips | Bin | Resource | ResourceBin | undefined): bin is ResourceBin {
    return bin != null && typeof bin === 'object' && 'iasData' in bin && 'clips' in bin;
  }

  isResource(bin: BinWithClips | Bin | Resource | ResourceBin | undefined): bin is Resource {
    return bin != null && typeof bin === 'object' && 'iasData' in bin;
  }

  ngOnInit() {
    this.getClips();
    this.showAllBins = this.resourceService.store$.value && 'showAll' in this.resourceService.store$.value ? this.resourceService.store$.value['showAll'] : false;
    assertTruthy(this.bin, '<mam-clip-bin-preview> requires property "bin"');
    this.selectionService.registerRow(this.bin);
  }

  getClips() {
    if (this.bin && 'iasId' in this.bin && this.bin.iasId) {
      this.assetService.searchClips(this.bin.iasId, undefined, undefined, 3).subscribe((clipResult) => {
        if (clipResult instanceof ErrorResponse) {
          return;
        }

        this.clips = (clipResult as ListResponse<Clip>).assets;
        this.cdr.markForCheck();
      });
    }
  }

  trackClip(index: number, clip: Clip) {
    return clip.name;
  }

  openRenameBin(title: string, name: string) {
    //If it's a BFF resource, change name.
    if (this.isResource(this.bin)) {
      const res = this.bin;
      name = res.iasData.label.name;
    }
    this.dialog
      .open(RenameBinDialog, {
        ...RenameBinDialog.dialogOptions, data: { title, name }
      })
      .afterClosed()
      .pipe(
        takeUntil(this.destroyed$),
        tap(() => this.resourceService.isLoading$.next(true)),
        concatMap(({ newTitle, name }) => (!newTitle || newTitle === title) ? EMPTY : of({ newTitle, name })),
        concatMap(({ newTitle, name }) => this.resourceService.renameClipBin(encodeURIComponent(name), newTitle)
          .pipe(
            map(() => ({ newTitle, name }))
          )
        )).subscribe({
          next: ({ newTitle, name }) => {
            const binEventResult = { ...this.bin, retiitled: newTitle };
            this.contentEvent.emit(binEventResult as unknown as ContentEvent);

            if ('displayName' in this.bin) {
              // (this.bin as BinWithClips).title = newTitle;
              this.bin = {...this.bin, title: newTitle};
            } else if ('iasData' in this.bin) {
              (this.bin as unknown as Resource).iasData['label']['displayName'] = newTitle;
              this.bin = {...this.bin, name: newTitle};
            }

            this.snackBar.message('Clip bin has been renamed successfully.');
            this.updateClipBinSharedLinkTitle(encodeURIComponent(name), newTitle);
            this.cdr.detectChanges();
          },
          error: (error) => {
            console.error('Clip bin could not be renamed.', error);
            this.snackBar.error('Clip bin could not be renamed.', undefined, error);
          },
          complete: () => {
            this.resourceService.isLoading$.next(false);
          }

        });
  }

  openDeleteBin(binValue: BinWithClips | Bin | Resource | ResourceBin) {
    let newVar: Resource;
    if (this.isResource(binValue)) {
      newVar = binValue;
    } else if ('displayName' in binValue && 'name' in binValue) {
      newVar = this.getResource(binValue.name);
    } else {
      throw new Error('Invalid binValue type');
    }
    this.dialog
    .open<DeleteBinDialog, DeleteBinDialogData>(DeleteBinDialog, {
      ...DeleteBinDialog.dialogOptions, data: {
        resource: newVar
      }
    })
    .afterClosed()
    .pipe(takeUntil(this.destroyed$))
    .subscribe((deletionConfirmed) => {
      const binEventResult = { ...this.bin, deleted: deletionConfirmed };
      this.contentEvent.emit(binEventResult as unknown as ContentEvent);
    });
  }

  openMoveFolder() {
    this.dialog.open(ClipBinBinMoveDialog, {
      ...ClipBinBinMoveDialog.dialogOptions, data: this.bin
    });
  }

  openShareBin(binValue: BinWithClips | Bin | Resource | ResourceBin) {
    this.resourceService.openShareBin(binValue);
  }

  ngOnDestroy() {

    const table:ResultItem[] = [];
    table.push(this.bin);

    // Unsubscribes all pending subscriptions.
    this.destroyed$.next();
    this.destroyed$.complete();
    this.selectionService.unregisterRow(table);
  }

  getTitle(binValue: BinWithClips | Bin | Resource | ResourceBin) {
    if (this.isResource(binValue)) {
      return binValue?.name;
    } else if ('title' in binValue) {
      return binValue?.title;
    } else {
      throw new Error('Invalid binValue type');
    }
  }

  getAssetCount(binValue: BinWithClips | Bin | Resource | ResourceBin): number {
    if (this.isLocationExpanded) return -1;
    if (this.isResource(binValue)) {
      return binValue?.iasData?.assetCount || 0;
    } else if ('assetCount' in binValue) {
      return Number.parseInt(binValue?.assetCount, 10) || 0;
    } else {
      throw new Error('Invalid binValue type');
    }
  }

  getClipBinId(binValue: BinWithClips | Bin | Resource | ResourceBin) {
    if (this.isResource(binValue)) {
      return binValue?.iasData?.label?.name;
    } else if ('name' in binValue) {
      return binValue?.name;
    } else {
      throw new Error('Invalid binValue type');
    }
  }

  /**
   * Returns the `bin` input as a `ResourceBin` if possible.
   * If `bin` is already a `ResourceBin`, it is returned directly.
   * If `bin` is a `BinWithClips`, a new `ResourceBin` is created with default `Resource` properties.
   * Otherwise, `undefined` is returned.
   */
  getBinAsResourceBin(): ResourceBin | Resource | undefined {
    if (this.isResourceBin(this.bin) || this.isResource(this.bin)) {
      return this.bin;
    } else if (typeof this.bin === 'object' && 'clips' in this.bin) {
      const binWithClips = this.bin as BinWithClips;
      return {
        id: binWithClips.name ?? '',
        iasId: binWithClips.name ?? '',
        displayName: binWithClips.title ?? '',
        name: binWithClips.name ?? '',
        createdAt: binWithClips.createTime ? new Date(binWithClips.createTime).toISOString() : '',
        level: 0,
        subTreeDepth: 0,
        directChildrenCount: 0,
        title: binWithClips.title ?? '',
        createTime: binWithClips.createTime,
        assetCount: binWithClips.assetCount ?? '',
        clips: binWithClips.clips,
        compReelInfo: binWithClips.compReelInfo
      };
    } else {
      return undefined;
    }
  }

  onIsExpandedChange(isExpanded: boolean) {
    this.isLocationExpanded = isExpanded;
  }

  navigateToParentFolder() {
    const iasId = this.getIasId();
    if (iasId) {
      this.resourceService
      .getParentResource(ResourceTypes.CLIPBIN, iasId)
      .pipe(tap((parent) => {
        if (parent) {
          this.router.navigate(['/folders', parent.id], { queryParamsHandling: 'merge' }).then(() => {
            this.cdr.markForCheck();
            return true;
          });
        }
      }))
      .subscribe();
    }
  }

  shouldShow3DotMenu() {
    return this.shouldShowNavigateToParentButton() || this.shouldShowOtherButtons();
  }

  shouldShowNavigateToParentButton() {
    return this.binValue && this.binValue.level !== undefined && this.binValue.level > 0;
  }

  shouldShowOtherButtons() {
    return this.isAdmin || !this.showAllBins;
  }

  getIasId() {
    const binAsResource = this.getBinAsResourceBin();
    return binAsResource?.iasId;
  }

  private getResource(id: string): Resource {
    return {
      createdAt: '', displayName: '', level: 0, name: '', subTreeDepth: 0, iasId: id, iasData: {
        label: {
          name: id
        }
      }
    };
  }

  isClipbin(value: BinWithClips | Bin | Resource | ResourceBin): boolean {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (value as any)?.type === 'clipbin';
  }

  private updateClipBinSharedLinkTitle(name: string, newTitle: string) {
    this.sharedLinkClipBinService.updateClipBinSharedLinkTitle(name, newTitle);
}

}
